import axios from "axios";
import {ModalType} from "@/types/util";

export default function useFuncUtil() {
    async function detectNigeria() {
        try {
            const response = await axios.get('https://ipinfo.io/json');
            const {country} = response.data;

            if (country === 'NG') {
                return Promise.resolve(true);
            } else {
                return Promise.resolve(false);
            }
        } catch (error: any) {
            return Promise.reject({message: `Error fetching IP information: ${error.message}`});
        }
    }

    function encodeBase64String(str) {
        const uint8Array = new TextEncoder().encode(str);
        return btoa(String.fromCharCode(...uint8Array));
    }

    function decodeBase64String(encodedStr) {
        const binaryString = atob(encodedStr);
        const uint8Array = new Uint8Array([...binaryString].map(char => char.charCodeAt(0)));
        return new TextDecoder().decode(uint8Array);
    }

    function showToastModal(title: string, message: string, modalType: ModalType) {
        const modalID = `${modalType === 'success' ? '#successModal' : '#errorModal'}`
        const modalRef = (document.getElementById(`${modalType === 'success' ? 'successModal' : 'errorModal'}`) as HTMLElement);

        $(modalID).on('show.bs.modal', function (event) {
            const modalTitle = modalRef.querySelector('#custom-modal-title');
            const modalMessage = modalRef.querySelector('#custom-modal-message');

            (modalTitle as HTMLElement).textContent = title;
            (modalMessage as HTMLElement).innerHTML = message;
        })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        $(modalID).modal('show')
    }


    return {
        detectNigeria,
        encodeBase64String,
        decodeBase64String,
        showToastModal,
    }
}