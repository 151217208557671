/* eslint-disable */
import {PB_API_instance} from "@/config/axios_config";
import {ZoneModel, ZonePaginatedModel} from "@/models/zones";

const state = {
    loading: false,
    error: false,
    zones: [] as ZoneModel[]
};

const getters = {
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
    getZones: (state) => state.zones,
};

const mutations = {
    setLoading(state, payload) {
        state.loading = payload;
    },
    setZones(state, payload) {
        state.zones = payload;
    },
    reset(state) {
        state.loading = false;
        state.error = false;
    },
};

const actions = {
    async fetchAllChurchZones({commit}, {churchID}) {
        try {
            commit('setLoading', true);
            const response: Record<string, any> = (await PB_API_instance.get(`zone/church?churchID=${churchID}&itemsPerPage=1000000&page=1`)).data;
            commit('setZones', new ZonePaginatedModel(response).zones)
        } catch (e: any) {
            console.log('Failed to fetch zone record', e.message)
        } finally {
            commit('setLoading', false);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
