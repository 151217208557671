import {Timestamp} from "@firebase/firestore";
import dayjs from "dayjs";

export default function useDate() {

    function getTime(date: Date) {
        return dayjs(date).format("h[:]mm A");
    }

    function getDay(date: Date) {
        return dayjs(date).format('DD')
    }

    function getMonth(date: Date) {
        return dayjs(date).format('MMM')
    }

    function getDate(date: Date) {
        return dayjs(date).format("MMM D [,] YYYY");
    }

    function getDateMonthAndYear(date: Date) {
        return dayjs(date).format("MMM D [,] YYYY");
    }

    function getDateTime(date: Date | null) {
        if (date) {
            return dayjs(date).format("MMM D [,] YYYY h[:]mm A");
        } else return '';
    }


    return {
        getTime,
        getDay,
        getMonth,
        getDateMonthAndYear,
        getDate,
        getDateTime,
    }
}