/* eslint-disable */
import {CountryModel, CountryPaginatedModel} from "@/models/countries";
import {PB_API_instance} from "@/config/axios_config";

const state = {
    countries: [] as CountryModel[],
    loading: false,
};

const getters = {
    get_countries: (state) => state.countries,
    get_loading: (state) => state.loading,
};

const mutations = {
    set_countries(state, value) {
        state.countries = value;
    },
    set_loading(state, value) {
        state.loading = value;
    },
};

const actions = {
    async fetch_countries({commit}) {

        try {
            commit("set_loading", true);
            const response: Record<string, any> = (await PB_API_instance.get('country')).data;
            commit('set_countries', new CountryPaginatedModel(response).countries.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            }));
        } catch (e) {
            return Promise.reject({message: 'Failed to fetch countries'})
        } finally {
            commit("set_loading", false);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
