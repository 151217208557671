import {instanceToPlain} from "class-transformer";
import type {PaginationData} from "@/interfaces/general.interface";

export class FamilyModel {
    id: string;
    familyHeadPhone: string;
    name: string;
    members: string[];
    parishID: string;
    churchID: string;
    createdBy: string;
    dateCreated: Date;
    dateUpdated: Date;

    constructor(
        id = '',
        familyHeadPhone = '',
        name = '',
        members: string[] = [],
        createdBy = '',
        parishID = '',
        churchID = '',
        dateCreated = new Date(),
        dateUpdated = new Date(),
    ) {
        this.id = id;
        this.familyHeadPhone = familyHeadPhone;
        this.name = name;
        this.members = members;
        this.createdBy = createdBy;
        this.parishID = parishID;
        this.churchID = churchID;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
    }

    static fromMongo(familyData: Record<string, any>):FamilyModel {
        const data = familyData;
        return <FamilyModel>instanceToPlain(new FamilyModel(
            data._id,
            data.familyHeadPhone,
            data.name,
            data.members,
            data.createdBy,
            data.parishID ? data.parishID._id : null,
            data.churchID ? data.churchID._id : null,
            data.dateCreated,
            data.dateUpdated,
        ))
    }
    toMongoJSON() {
        return {
            familyHeadPhone: this.familyHeadPhone ? this.familyHeadPhone : null,
            name: this.name ? this.name.trim() : null,
            members: this.members,
            createdBy: this.createdBy ? this.createdBy : null,
            parishID: this.parishID ? this.parishID : null,
            churchID: this.churchID ? this.churchID : null,
            dateCreated: new Date(this.dateCreated).toISOString(),
            dateUpdated: new Date(this.dateUpdated).toISOString(),
        }
    }

}

export class FamilyPaginatedModel {
    status: string;
    families: FamilyModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.families = response.data.map((doc: Record<string, any>) => FamilyModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}