import {PaginationData} from "@/interfaces/general.interface";

export class ProvinceModel {
    id: string;
    archBishop: string;
    countryID: string;
    createdAt: Date;
    createdBy: string;
    name: string;
    updatedAt: Date;

    constructor(
        id = '',
        archBishop = '',
        countryID = '',
        createdAt = new Date(),
        createdBy = '',
        name = '',
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.archBishop = archBishop;
        this.countryID = countryID;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.name = name;
        this.updatedAt = updatedAt;
    }


    static fromMongo(provinceData: Record<string, any>): ProvinceModel {
        const data = provinceData;
        return new ProvinceModel(
            data._id,
            data.archBishop,
            data.countryID,
            data.createdAt,
            data.createdBy,
            data.name,
            data.updatedAt,
        )
    }

    toMongoJSON() {
        return {
            createdBy: this.createdBy ? this.createdBy : null,
            countryID: this.countryID ? this.countryID :null,
            archBishop: this.archBishop ? this.archBishop :null,
            name: this.name ? this.name :null,
            /*createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}

export class ProvincePaginatedModel {
    status: string;
    provinces: ProvinceModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.provinces = response.data.map((doc: Record<string, any>) => ProvinceModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}