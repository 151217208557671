import {PaginationData} from "@/interfaces/general.interface";

export class CountryModel {
    id: string;
    countryCode: string;
    isoCode: string;
    name: string;
    dateCreated: Date;
    dateUpdated: Date;

    constructor(
        id = '',
        countryCode = '',
        isoCode = '',
        name = '',
        dateCreated = new Date(),
        dateUpdated = new Date(),
    ) {
        this.id = id
        this.countryCode = countryCode;
        this.isoCode = isoCode;
        this.name = name;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
    }


    static fromMongo(countryData: Record<string, any>): CountryModel {
        const data = countryData;
        return new CountryModel(
            data._id,
            data.countryCode,
            data.isoCode,
            data.name,
            data.dateCreated,
            data.dateUpdated,
        )
    }

    toMongoJSON() {
        return {
            countryCode: this.countryCode ? this.countryCode : null,
            isoCode: this.isoCode ? this.isoCode : null,
            name: this.name ? this.name : null,
            /*dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,*/
        }
    }
}

export class CountryPaginatedModel {
    status: string;
    countries: CountryModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.countries = response.data.map((doc: Record<string, any>) => CountryModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}