import {PaginationData} from "@/interfaces/general.interface";

export class ContactUsModel {
    id: string;
    email: string;
    message: string;
    topic: string;
    name: string;
    phone: string;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(
        id = '',
        email = '',
        message = '',
        topic = '',
        name = '',
        phone = '',
        createdBy = '',
        createdAt = new Date(),
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.email = email;
        this.message = message;
        this.topic = topic;
        this.name = name;
        this.phone = phone;
        this.createdBy = createdBy;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    static fromMongo(contactUs: Record<string, any>) {
        const data = contactUs;
        return new ContactUsModel(
            data._id,
            data.email,
            data.message,
            data.topic,
            data.name,
            data.phone,
            data.createdBy,
            data.createdAt,
            data.updatedAt,
        )
    }

    toMongoJSON() {
        return {
            email: this.email ? this.email : null,
            message: this.message ? this.message : null,
            topic: this.topic ? this.topic : null,
            name: this.name ? this.name : null,
            phone: this.phone ? this.phone : null,
            createdBy: this.createdBy ? this.createdBy : null,
            /*createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}

export class ContactUsPaginatedModel {
    status: string;
    messages: ContactUsModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.messages = response.data.map((doc: Record<string, any>) => ContactUsModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}