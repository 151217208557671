import type {AuthType, UserType} from "@/types/util";
import type {GeoLocation, PaginationData} from "@/interfaces/general.interface";
import {ParishModel} from "@/models/parish";
import {DioceseModel} from "@/models/dioceses";
import {ChurchModel} from "@/models/church";

//import type {ParishInterface} from "@/interfaces/parish.interface";

export class UserModel {
    id: string;
    authType: AuthType;
    baptismalName: string;
    bio: string;
    dateCreated: Date;
    dateUpdated: Date;
    dateOfBirth: string;
    email: string;
    firstName: string;
    followers: string[];
    followings: string[];
    gender: string | null;
    geoLocation: GeoLocation | null; //{ type: string; coordinates: number[] };
    ip: string;
    isAdminOf: ParishModel[];
    isParishRep: boolean;
    isParishPriest: boolean;
    isParishPriestOf: string[];
    isDiocesanAdmin: boolean;
    isDiocesanAdminOf: DioceseModel[];
    isChurchAdmin: boolean;
    isChurchAdminOf: ChurchModel[];
    lastSeen: Date;
    otherName: string;
    parishId: ParishModel | null;
    phone: string;
    profilePicture: string;
    registrationTokens: string[];
    reputation: number;
    surname: string;
    type: UserType;

    constructor(
        id = '',
        authType: AuthType = 'custom',
        baptismalName = '',
        bio = '',
        dateCreated = new Date(),
        dateUpdated = new Date(),
        dateOfBirth = '',
        email = '',
        firstName = '',
        followers = [],
        followings = [],
        gender: string | null = null,
        geoLocation = null,
        ip = '',
        isAdminOf: ParishModel[] = [],
        isParishRep = false,
        isParishPriest = false,
        isParishPriestOf = [],
        isDiocesanAdmin = false,
        isDiocesanAdminOf = [],
        isChurchAdmin = false,
        isChurchAdminOf = [],
        lastSeen = new Date(),
        otherName = '',
        parishId: null | ParishModel = null,
        phone = '',
        profilePicture = '',
        registrationTokens = [],
        reputation = 1,
        surname = '',
        type: UserType = 'user',
    ) {
        this.id = id;
        this.authType = authType;
        this.baptismalName = baptismalName;
        this.bio = bio;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.dateOfBirth = dateOfBirth;
        this.email = email;
        this.firstName = firstName;
        this.followers = followers;
        this.followings = followings;
        this.gender = gender;
        this.geoLocation = geoLocation;
        this.ip = ip;
        this.isAdminOf = isAdminOf;
        this.isParishRep = isParishRep;
        this.isParishPriest = isParishPriest;
        this.isParishPriestOf = isParishPriestOf;
        this.isDiocesanAdmin = isDiocesanAdmin;
        this.isDiocesanAdminOf = isDiocesanAdminOf;
        this.isChurchAdmin = isChurchAdmin;
        this.isChurchAdminOf = isChurchAdminOf;
        this.lastSeen = lastSeen;
        this.otherName = otherName;
        this.parishId = parishId;
        this.phone = phone;
        this.profilePicture = profilePicture;
        this.registrationTokens = registrationTokens;
        this.reputation = reputation;
        this.surname = surname;
        this.type = type;
    }


    static fromMongoDB(userData: Record<string, any>): UserModel {
        const data = userData;

        return new UserModel(
            data._id,
            data.authType,
            data.baptismalName,
            data.bio,
            data.dateCreated,
            data.dateUpdated,
            data.dateOfBirth,
            data.email,
            data.firstName,
            data.followers,
            data.followings,
            data.gender,
            data.geoLocation,
            data.ip,
            data.isAdminOf ? data.isAdminOf.filter((parish: Record<string, any>) => Boolean(parish)).map((parish: Record<string, any>) => ParishModel.fromMongo(parish)) : [],
            data.isParishRep,
            data.isParishPriest,
            data.isParishPriestOf,
            data.isDiocesanAdmin,
            data.isDiocesanAdminOf ? data.isDiocesanAdminOf.filter((diocese: Record<string, any>) => Boolean(diocese)).map((diocese: Record<string, any>) => DioceseModel.fromMongo(diocese)) : [],
            data.isChurchAdmin,
            data.isChurchAdminOf ? data.isChurchAdminOf.filter((church: Record<string, any>) => Boolean(church)).map((church: Record<string, any>) => ChurchModel.fromMongo(church)) : [],
            data.lastSeen,
            data.otherName,
            data.parishId ? ParishModel.fromMongo(data.parishId) : null,
            data.phone,
            data.profilePicture,
            data.registrationTokens,
            data.reputation,
            data.surname,
            data.type
        )

    }


    toMongoJSON() {
        return {
            //_id: this.id,
            authType: this.authType,
            baptismalName: this.baptismalName ? this.baptismalName : null,
            bio: this.bio ? this.bio : null,
            /*dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,*/
            dateOfBirth: this.dateOfBirth ? this.dateOfBirth : null,
            email: this.email,
            firstName: this.firstName ? this.firstName : null,
            followers: this.followers,
            followings: this.followings,
            gender: this.gender ? this.gender : null,
            geoLocation: this.geoLocation ? this.geoLocation : null, //this.geoLocation ? {type: 'Point', coordinates: [this.geoLocation.geopoint.longitude, this.geoLocation.geopoint.latitude]}  : null,
            ip: this.ip ? this.ip : null,
            isAdminOf: this.isAdminOf.map(parish => parish.id),
            isParishRep: this.isParishRep,
            isParishPriest: this.isParishPriest,
            isParishPriestOf: this.isParishPriestOf,
            isDiocesanAdmin: this.isDiocesanAdmin,
            isDiocesanAdminOf: [...new Set(this.isDiocesanAdminOf.map(diocese => diocese.id))],
            isChurchAdmin: this.isChurchAdmin,
            isChurchAdminOf: this.isChurchAdminOf.map(church => church.id),
            lastSeen: new Date().toISOString(),
            otherName: this.otherName ? this.otherName : null,
            parishId: this.parishId ? this.parishId.id : null,
            phone: this.phone ? this.phone : null,
            profilePicture: this.profilePicture ? this.profilePicture : null,
            registrationTokens: this.registrationTokens && this.registrationTokens.length > 0 ? this.registrationTokens.filter(token => Boolean(token)) : [],
            reputation: this.reputation ? this.reputation : 1,
            surname: this.surname ? this.surname : null,
            type: this.type ? this.type : 'user',
        }
    }
}

export class UserMongoPaginatedModel {
    status: string;
    users: UserModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = {
        status: 'success',
        data: [],
        pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}
    }) {
        this.status = response.status;
        this.users = response.data.map((doc: Record<string, any>) => UserModel.fromMongoDB(doc));
        this.pagination = response.pagination;
    }
}