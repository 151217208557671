<template>
  <div>
    <Navbar/>
    <div class="top-icon">
      <div></div>
      <div class="cross">
        <img
            style="margin-top: 20px"
            src="../assets/images/cross1.png"
            width="40"
            alt=""
        />
      </div>
    </div>
    <div class="container top">
      <div class="Profile">
        <div class="left"></div>
        <div class="right"></div>
        <div class="Profile-title">
          <h1>Profile</h1>
        </div>

        <form class="Profile-form" @submit.prevent="updateProfile">
          <div class="image">
            <div class="image-container">
              <img
                  v-if="profileImage || user.profilePicture"
                  :src="profileImage || user.profilePicture"
                  alt=""
                  width="150"
                  height="150"
                  style="border-radius: 50%"
              />
              <img
                  v-else
                  src="../assets/icon/profile.jpg"
                  width="150"
                  height="150"
                  style="border-radius: 50%"
              />
              <!-- <div v-for="(file, index) in files" v-bind:key="file.name">
                  <img class="preview" width="150" height="150" :ref="'file' + index" style="border-radius:50%">
              </div> -->
              <input
                  @change="load_profile_img"
                  style="display: none"
                  type="file"
                  ref="imageFile"
                  accept="image/*"
              />
            </div>
            <i class="fa fa-camera" @click="$refs.imageFile.click()"></i>

            <h1 class="form-text-header">
              {{ user.surname }} {{ user.firstName }} (<span
                style="color: #009688"
            >Parishioner</span
            >)
            </h1>
          </div>

          <div class="form-item">
            <label for="Parishname">First Name</label>
            <input
                type="text"
                v-model="user.surname"
                placeholder="Enter First Name"
                id="Parishname"
                required
            />
          </div>
          <div class="form-item">
            <label for="Parishaddress">Last Name</label>
            <input
                type="text"
                v-model="user.firstName"
                placeholder="Enter Last Name"
                id="Parishaddress"
                required
            />
          </div>

          <div class="form-item">
            <label for="number3">Phone Number</label>
            <input
                type="tel"
                v-model="user.phone"
                placeholder="+234 810-5467-939"
                id="number3"
                required
            />
          </div>

          <div class="form-item">
            <label for="email3">Email</label>
            <input
                type="email"
                v-model="user.email"
                placeholder="james...@gmail.com"
                id="email3"
                required
                disabled
            />
          </div>

          <button type="submit" :disabled="!userInputHaschanged">
            <span v-if="loading"
            ><i class="fa fa-spinner fa-2x fa-spin"></i></span
            ><span v-else>Update Profile</span>
          </button>
        </form>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import {computed, defineComponent, reactive, toRefs, watch} from "vue";
import Footer from "./Footer.vue";
import Navbar from "./Navbar.vue";
import {useStore} from "vuex";
import {useToast} from "vue-toastification";

export default defineComponent({
  /* eslint-disable */
  name: "Profile",
  components: {
    Navbar,
    Footer,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const state = reactive({
      profileImage: "",
      image: "",
      userInputHaschanged: false,
    });
    const user = computed(() => store.getters["user/get_user"]);
    const loading = computed(() => store.getters["user/is_loading"]);
    // console.log(user.value.data.parishId._id);
    watch(() => user.value, () => {
      state.userInputHaschanged = true;
    }, {immediate: false, deep: true});

    function load_profile_img(event) {
      console.log("image uploaded");
      state.image = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        state.profileImage = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }

    async function updateProfile() {
      try {
        const response = await store.dispatch("user/updateUser", [user.value, state.image]);
        toast.success(response.message);
        state.image = state.profileImage = "";
        state.userInputHaschanged = false;
      } catch (e) {
        toast.error(e.message);
      }
    }

    return {
      updateProfile,
      user,
      ...toRefs(state),
      load_profile_img,
      loading,
    };
  },
});
</script>

<style scoped>
.top-icon {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.cross {
  background-color: rgba(0, 150, 136, 0.13);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 70px;
  justify-content: right;
}

.image-container {
  border-radius: 50%;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  position: relative;
  width: 150px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
}

.fa.fa-camera {
  position: absolute;
  font-size: 29px;
  color: #009688;
  margin-left: 50px;
  cursor: pointer;
  margin-top: -30px;
}

.et {
  background-image: url(../assets/images/Frame1.png);
  background-position: right;
  background-clip: border-box;
  margin-top: -500px;
  transform: rotate(180deg);
  margin-bottom: 350px;
  padding-top: 150px;
  right: 0;
  width: 100%;
  background-size: 80px;
  background-repeat: no-repeat;
}

.et img {
  position: absolute;
  right: 12px;
  transform: rotate(180deg);
  bottom: 50px;
}

.top {
  margin-top: -220px;
}

.popup-inner h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
}

.popup-inner .sub-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(49, 49, 49, 0.79);
}

.Profile-title {
  width: 50%;
  margin: auto;
  margin-bottom: 30px;
}

.Profile-title h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 38px;
  text-align: center;
}

.Profile-title p {
  font-size: 14px;
  text-align: center;
}

.form-text-header {
  color: black;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 40px;
  margin-top: 20px;
  text-align: center;
}

.Profile-form {
  width: 65%;
  margin: auto;
  margin-bottom: 100px;
  height: auto;
  padding: 40px;
  background: #ffffff;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.Profile-form .form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-item label {
  text-align: left;
  font-size: 16px;
  color: black;
  font-family: sans-serif;
  font-weight: 600;
}

.form-item input {
  border-radius: 6px;
  margin-top: -5px;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  border: none;
  background-color: #ececec;
}

.form-item select {
  outline: none;
  margin-top: -5px;
  border-radius: 6px;
  padding: 10px;
  padding-left: 20px;
  width: 100%;
  border: none;
  background-color: #ececec;
}

.form-text {
  color: black;
  font-size: 16px;
  font-weight: 700;
  margin-top: 40px;
  text-align: left;
}

.Profile-form button {
  width: 380px;
  height: 60px;
  padding: 13px;
  font-weight: 600;
  outline: none;
  background-color: #009688;
  color: #fff;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.Profile-form button:hover {
  background: #a7a6a6;
  color: #fff;
}

.left {
  background-image: url(../assets/images/virgen.png);
  background-position: left;
  background-size: 200px;
  background-repeat: no-repeat;
  margin-top: 300px;
  margin-bottom: -480px;
  width: 100%;
  height: 200px;
}

.right {
  background-image: url(../assets/images/cross1.png);
  background-position: right;
  background-size: 200px;
  margin-top: 280px;
  margin-bottom: -400px;
  background-repeat: no-repeat;
  width: 100%;
  z-index: 4;
  height: 200px;
}

@media screen and (max-width: 768px) {
  .Profile-form {
    width: 100%;
    margin: auto;
    padding: 6px;
    margin-bottom: 70px;
    margin-top: 100px;
    height: auto;
    background: none;
    box-shadow: none;
  }

  .Profile-form button {
    width: 80%;
    padding: 5px;
  }

  .top {
    margin-top: 70px;
  }

  .Profile-title {
    width: 90%;
    margin-bottom: -60px;
  }

  .right {
    background-size: 70px;
    height: 70px;
    background-position: left;
    margin-top: 0px;
    margin-bottom: -30px;
  }

  .left {
    background-size: 80px;
    height: 80px;
    background-position: right;
    margin-top: 0px;
    margin-bottom: -78px;
  }

  .top-icon,
  .et {
    display: none;
  }

  .popup-inner h2 {
    font-size: 20px;
  }

  .popup-inner .sub-title {
    font-size: 14px;
  }
}
</style>
