import {instanceToPlain} from "class-transformer";
import type {PaginationData} from "@/interfaces/general.interface";

export class ProfessionCategoryModel {
    id: string;
    createdBy: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(
        id = '',
        name = '',
        createdBy = '',
        createdAt = new Date(),
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.name = name;
        this.createdBy = createdBy;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    static fromMongo(professionCategoryData: Record<string, any>): ProfessionCategoryModel {
        const data = professionCategoryData;
        return <ProfessionCategoryModel>instanceToPlain(new ProfessionCategoryModel(
            data._id,
            data.name,
            data.createdBy,
            data.createdAt,
            data.updatedAt
        ))
    }

    toMongoJSON() {
        return {
            name: this.name ? this.name : null,
            createdBy: this.createdBy ? this.createdBy : null,
            /*createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}

export class ProfessionCategoriesPaginatedModel {
    status: string;
    professionCategories: ProfessionCategoryModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.professionCategories = response.data.map((doc: Record<string, any>) => ProfessionCategoryModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}