import {PaginationData} from "@/interfaces/general.interface";

export class ParishProgramModel {
    id: string;
    title: string;
    parishID: string;
    body: string;
    image: string;
    isVisible: boolean;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(
        id = '',
        body = '',
        title = '',
        image = '',
        isVisible = true,
        parishID = '',
        createdBy = '',
        createdAt = new Date(),
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.body = body;
        this.title = title;
        this.image = image;
        this.isVisible = isVisible;
        this.parishID = parishID;
        this.createdBy = createdBy;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    static fromMongo(parishProgramData: Record<string, any>): ParishProgramModel {
        const data = parishProgramData;
        return new ParishProgramModel(
            data._id,
            data.body,
            data.title,
            data.image,
            data.isVisible,
            data.parishID ? data.parishID._id : null,
            data.createdBy,
            data.createdAt,
            data.updatedAt
        )
    }


    toMongoJSON() {
        return {
            title: this.title ? this.title : null,
            parishID: this.parishID ? this.parishID : null,
            body: this.body ? this.body : null,
            image: this.image ? this.image : null,
            isVisible: this.isVisible,
            createdBy: this.createdBy ? this.createdBy : null,
            /*createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}

export class ParishProgramPaginatedModel {
    status: string;
    parishPrograms: ParishProgramModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.parishPrograms = response.data.map((doc: Record<string, any>) => ParishProgramModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}