<template>
  <div>
    <Navbar/>
    <div class="container top">
      <form class="login-form">
        <div class="left"></div>
        <div class="right"></div>
        <div class="login-title">
          <h2>Reset Password</h2>
          <p>Forgot your Password? Lets help get it back</p>
        </div>
        <div class="login-item">
          <label for="email4">Email</label>
          <input type="email" v-model="email" placeholder="james...@gmail.com" name="name" id="email4" required>
        </div>

        <button class="mb-3" @click.prevent="reset_password"><span v-if="loading"><i class="fa fa-spinner fa-2x fa-spin"
                                                                                     aria-hidden="true"></i></span><span
            v-else> Reset Password</span></button>
      </form>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from './Navbar.vue'
import Footer from './Footer.vue'
import {computed, defineComponent, ref} from 'vue'
import {useStore} from "vuex";
import {useToast} from "vue-toastification";
import {validateEmail} from "@/utils/plugins/geofirex/mixins/methodMixins";
import {useRouter} from 'vue-router';

export default defineComponent({
  name: "ResetPassword",
  components: {
    Navbar,
    Footer
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const store = useStore();
    const email = ref('');

    const loading = computed(() => store.getters['auth/getLoading']);

    async function reset_password() {
      try {
        if (!email.value) {
          return toast.info("Please enter your Email Address")
        } else if (!validateEmail(email.value)) {
          return toast.info("Please enter a valid Email Address")
        }

        let response = await store.dispatch('auth/reset_password', email.value)
        await router.push({name: 'home'})
        toast.success(response.message);
      } catch (e) {
        toast.error(e.message);
      }
    }

    return {
      reset_password,
      email,
      loading
    }
  }
})
</script>

<style scoped>

.top {
  margin-top: 190px;
}

.login-form {
  width: 790px;
  margin: auto;
  margin-top: -50px;
  margin-bottom: 40px;
  height: auto;
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 90px;
  background: #FFFFFF;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.login-form .login-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.login-item label {
  text-align: left;
  font-size: 16px;
  color: rgba(15, 10, 10, 1);
  font-family: sans-serif;
  font-weight: 600;
}

.login-form .login-title h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

.login-title p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #313131;
}

.login-title p span a {
  color: #009688;
  text-decoration: none;
}

.login-item input {
  border-radius: 6px;
  margin-top: -5px;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  border: none;
  background-color: rgba(236, 236, 236, 1);
}

.button {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form button {
  width: 45%;
  padding: 13px;
  font-weight: 600;
  outline: none;
  background-color: #049b8c;
  color: #fff;
  border: 1px solid #009688;
  border-radius: 5px;
  margin: auto;
}

.login-form button:hover {
  background: #FFFFFF;
  color: #009688;
}

.left {
  background-image: url(../assets/images/virgen.png);
  background-size: 70px;
  background-repeat: no-repeat;
  display: none;
  width: 100%;
  height: 70px;
}

.right {
  background-image: url(../assets/images/cross1.png);
  background-size: 80px;
  display: none;
  background-repeat: no-repeat;
  width: 100%;
  height: 80px;
}

@media screen and (max-width: 1024px) {
  .login-form {
    width: 100%;
    margin: auto;
    margin-bottom: 80px;
    margin-top: 20px;
    height: auto;
    padding: 10px;
    padding-bottom: 150px;
    background: none;
    box-shadow: none;
  }

  .top {
    margin-top: 60px;
  }

  .login-title {
    margin-top: -50px;
    margin-bottom: 30px;
  }

  .login-form .login-title h2 {
    font-size: 25px;
  }

  .login-title p {
    width: 50%;
    margin: auto;
  }

  .button {
    margin-top: 40px;
  }

  .login-form .button2,
  .login-form button {
    width: 100%;
    padding: 12px;
  }

  .right {
    background-size: 70px;
    height: 70px;
    display: block;
    background-position: left;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .left {
    background-size: 80px;
    height: 80px;
    display: block;
    background-position: right;
    margin-top: 0px;
    margin-bottom: -78px;
  }
}

@media screen and (max-width: 414px) {
  .login-title p {
    width: 45%;
    margin: auto;
  }
}

@media screen and (min-width: 210px)and (max-width: 375px) {
  .login-title p {
    width: 55%;
    margin: auto;
  }

  .login-title h2 {
    font-size: 16px;
  }
}
</style>