import {PaginationData} from "@/interfaces/general.interface";

export class DemoModel {
    id: string;
    createdAt: Date;
    demoDate: Date;
    demoNote: string;
    parishAddress: string;
    parishName: string;
    prospectEmail: string;
    prospectName: string;
    prospectPhone: string;
    updatedAt: Date;

    constructor(
        id = '',
        parishName = '',
        parishAddress = '',
        prospectName = '',
        prospectEmail = '',
        prospectPhone = '',
        demoNote = '',
        demoDate = new Date(),
        createdAt = new Date(),
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.parishName = parishName;
        this.parishAddress = parishAddress;
        this.prospectName = prospectName;
        this.prospectEmail = prospectEmail;
        this.prospectPhone = prospectPhone;
        this.demoNote = demoNote;
        this.demoDate = demoDate;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }


    static fromMongo(demoData: Record<string, any>): DemoModel {
        const data = demoData;
        return new DemoModel(
            data._id,
            data.parishName,
            data.parishAddress,
            data.prospectName,
            data.prospectEmail,
            data.prospectPhone,
            data.demoNote,
            data.demoDate,
            data.createdAt,
            data.updatedAt,
        )
    }

    toMongoJSON() {
        return {
            parishName: this.parishName ? this.parishName  : null,
            parishAddress: this.parishAddress ? this.parishAddress : null,
            prospectName: this.prospectName ? this.prospectName : null,
            prospectEmail: this.prospectEmail ? this.prospectEmail : null,
            prospectPhone: this.prospectPhone ? this.prospectPhone :null,
            demoNote: this.demoNote ? this.demoNote : null,
            demoDate: this.demoDate ? new Date(this.demoDate).toISOString() : null,
            /*createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}

export class DemoPaginatedModel {
    status: string;
    demos: DemoModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.demos = response.data.map((doc: Record<string, any>) => DemoModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}