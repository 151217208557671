<template>
  <router-view/>
  <success-modal/>
  <error-modal/>
</template>

<style>
#app {
  font-family: "Lato", sans-serif;
  text-align: center;
  background-color: #F5F5F5;
  box-sizing: border-box;
}

</style>
