import {logEvent} from "firebase/analytics"
import {analyticsRef} from "@/config/firebase";

export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
       async logLoginEvent(context, {method}){
            try {
                await logEvent(analyticsRef, 'login', {
                    method: method
                });
                return Promise.resolve(null)
            }catch (e) {
              return Promise.reject(e);
            }

        },
       async logSignupEvent(context, {method}){
           try {
               await logEvent(analyticsRef, 'sign_up', {
                   method: method
               }) ;
               return Promise.resolve(null)
           }catch (e) {
               return Promise.reject(e);
           }

        }
    }

}