/* eslint-disable */
import {DioceseModel, DiocesePaginatedModel} from "@/models/dioceses";
import {PB_API_instance} from "@/config/axios_config";

const state = {
    diocese: [] as DioceseModel[],
    loading: false,
};

const getters = {
    get_diocese: (state) => state.diocese,
    get_loading: (state) => state.loading,
};

const mutations = {
    set_diocese(state, value) {
        state.diocese = value;
    },
    set_loading(state, value) {
        state.loading = value;
    },
};

const actions = {
    async fetch_diocese({commit}) {

        try {
            commit("set_loading", true);
            const response: Record<string, any> = (await PB_API_instance.get('diocese')).data;
            commit('set_diocese', new DiocesePaginatedModel(response).dioceses)
        } catch (e) {
            return Promise.reject({message: 'Failed to fetch dioceses'})
        } finally {
            commit("set_loading", false);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
