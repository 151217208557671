import {DocumentSnapshot, serverTimestamp, Timestamp} from "firebase/firestore";
import {instanceToPlain} from "class-transformer";
import type {PaginationData} from "@/interfaces/general.interface";

export class ZoneModel {
    id: string;
    contact: string;
    createdBy: string;
    dateCreated: Date;
    dateUpdated: Date;
    meetingTimes: string;
    meetingVenue: string;
    name: string;
    parishID: string;
    churchID: string;
    president: string;
    streets: string[];

    constructor(
        id = '',
        contact = '',
        meetingTimes = '',
        meetingVenue = '',
        name = '',
        president = '',
        parishID = '',
        churchID = '',
        streets = [],
        createdBy = '',
        dateCreated = new Date(),
        dateUpdated = new Date()
    ) {
        this.id = id;
        this.contact = contact;
        this.meetingTimes = meetingTimes;
        this.meetingVenue = meetingVenue;
        this.name = name;
        this.president = president;
        this.parishID = parishID;
        this.churchID = churchID;
        this.streets = streets;
        this.createdBy = createdBy;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
    }

    static fromFirestore(zoneData: DocumentSnapshot): ZoneModel {
        const id = zoneData.id;
        const data = zoneData.data()!;
        return <ZoneModel>instanceToPlain(new ZoneModel(
            id,
            data.contact ? data.contact : '',
            data.meetingTime ? data.meetingTime : '',
            data.meetingVenue ? data.meetingVenue : '',
            data.name ? data.name : '',
            data.president ? data.president : '',
            data.parishID ? data.parishID : '',
            data.churchID ? data.churchID : '',
            data.streets ? data.streets : [],
            data.createdBy ? data.createdBy : '',
            data.dateCreated ? data.dateCreated.toDate() : Timestamp.now().toDate(),
            data.dateUpdated ? data.dateUpdated.toDate() : Timestamp.now().toDate()
        ))
    }


    static fromMongo(zoneData: Record<string, any>): ZoneModel {
        const data = zoneData;
        return <ZoneModel>instanceToPlain(new ZoneModel(
            data._id,
            data.contact,
            data.meetingTimes,
            data.meetingVenue,
            data.name,
            data.president,
            data.parishID ? data.parishID._id : null,
            data.churchID ? data.churchID._id : null,
            data.streets,
            data.createdBy,
            data.dateCreated,
            data.dateUpdated
        ))
    }


    toJSON() {
        return {
            contact: this.contact,
            meetingTime: this.meetingTimes,
            meetingVenue: this.meetingVenue,
            name: this.name,
            president: this.president,
            parishID: this.parishID,
            streets: this.streets,
            createdBy: this.createdBy,
            dateCreated: Timestamp.fromDate(new Date(this.dateCreated)),
            dateUpdated: serverTimestamp(),
        }
    }

    toMongoJSON() {
        return {
            contact: this.contact ? this.contact : null,
            createdBy: this.createdBy ? this.createdBy : null,
            /*dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,*/
            meetingTimes: this.meetingTimes ? this.meetingTimes : null,
            meetingVenue: this.meetingVenue ? this.meetingVenue : null,
            name: this.name ? this.name : null,
            parishID: this.parishID ? this.parishID : null,
            churchID: this.churchID ? this.churchID : null,
            president: this.president ? this.president : null,
            streets: this.streets,
        }
    }
}

export class ZonePaginatedModel {
    status: string;
    zones: ZoneModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = {
        status: 'success',
        data: [],
        pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}
    }) {
        this.status = response.status;
        this.zones = response.data.map((doc: Record<string, any>) => ZoneModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}