/* eslint-disable */

import {getDownloadURL, ref} from "firebase/storage";
import {deleteObject, storage, uploadBytes} from "@/config/firebase";
import imageCompression from "browser-image-compression";
import {JobApplicationModel} from "@/models/jobApplication";
import {PB_API_instance} from "@/config/axios_config";

const state = {
    loading: false,
    error: false,
}

const getters = {
    getLoading: state => state.loading,
    getError: state => state.error,
}

const mutations = {

    setLoading(state, payload) {
        state.loading = payload
    },

    setError(state, payload) {
        state.error = payload
    },
    reset(state) {
        state.loading = false;
        state.error = false;
    }
}

const actions = {

    async handleImageCompression(context, image) {

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            try {
                const compressedFile = await imageCompression(image, options);
                resolve(compressedFile)
            } catch (error: any) {
                reject(error.message);
            }
        })

    },

    async uploadImageToFirebase({dispatch}, {file, folder}) {

        return new Promise(async (resolve) => {
            // Create the file metadata
            /** @type {any} */
            const metadata = {
                contentType: 'image/jpeg'
            };
            //compress image that are more than 1mb in size
            file = file && file.size / 1024 / 1024 > 1 ? await dispatch('handleImageCompression', file) : file
            let [fileName] = file.name.split(" ").join('').split('.');
            fileName = fileName + "" + Date.now()
            //.replace(/\s/g,'')
            const storageRef = ref(storage, `${folder}/` + fileName);
            const uploadTask = await uploadBytes(storageRef, file, metadata);
            const imageURL = await getDownloadURL(uploadTask.ref);
            resolve(imageURL);
        })

    },

    async uploadPDFToFirebase({dispatch}, {file, folder}) {

        return new Promise(async (resolve) => {
            // Create the file metadata
            /** @type {any} */
           /* const metadata = {
                contentType: 'application/pdf'
            };*/

            let [fileName] = file.name.split(" ").join('').split('.');
            fileName = fileName + "" + Date.now()
            //.replace(/\s/g,'')
            const storageRef = ref(storage, `${folder}/` + fileName);
            const uploadTask = await uploadBytes(storageRef, file);
            const imageURL = await getDownloadURL(uploadTask.ref);
            resolve(imageURL);
        })

    },


    async deleteImageFromStorage(context, url) {
        try {
            // Create a reference to the file to delete
            const imageRef = ref(storage, url);

            await deleteObject(imageRef)
        } catch (e: any) {
            console.log(e.message)
        }
    },

    async saveJobApplication({commit, dispatch}, [application, images]: [JobApplicationModel, File[]]) {
        try {
            commit('setLoading', true)
            application.resume = await Promise.all(images.map(image => dispatch('uploadPDFToFirebase', {
                file: image,
                folder: `Resume/${application.fullName.split(' ').join('')}`
            })));
            await PB_API_instance.post('job-application', application.toMongoJSON());
            return Promise.resolve({message: 'We will reach out to you via Email'});
        } catch (e) {
            return Promise.reject({message: 'Sorry We cant process your application at the moment, please try again after some time'})
        } finally {
            commit('setLoading', false)
        }
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}