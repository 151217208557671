/* eslint-disable */

import {addDoc, parishClaimsRef} from "@/config/firebase";
import {ParishClaimModel} from "@/models/parishClaim";
import {PB_API_instance} from "@/config/axios_config";

const state = {
    loading: false,
    error: false,
};

const getters = {
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
};

const mutations = {
    setLoading(state, payload) {
        state.loading = payload;
    },
    reset(state) {
        state.loading = false;
        state.error = false;
    },
};

const actions = {
    async submitClaim({commit}, claim: ParishClaimModel) {
        try {
            commit("setLoading", true);
            await PB_API_instance.post('parish-claim', claim.toMongoJSON());
            return Promise.resolve({message: 'Parish Claim Successfully sent'});
        } catch (e) {
            return Promise.reject(e);
        } finally {
            commit("setLoading", false);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
