import {instanceToPlain} from "class-transformer";
import type {PaginationData} from "@/interfaces/general.interface";


export class ProfessionModel {
    id: string;
    createdBy: string;
    name: string;
    professionCategoryID: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(
        id = '',
        name = '',
        professionCategoryID = '',
        createdBy = '',
        createdAt = new Date(),
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.name = name;
        this.professionCategoryID = professionCategoryID;
        this.createdBy = createdBy;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    static fromMongo(professionData: Record<string, any>): ProfessionModel {
        const data = professionData;
        return <ProfessionModel>instanceToPlain(new ProfessionModel(
            data._id,
            data.name,
            data.professionCategoryID,
            data.createdBy,
            data.createdAt,
            data.updatedAt
        ));
    }

    toMongoJSON() {
        return {
            name: this.name ? this.name :null,
            professionCategoryID: this.professionCategoryID ? this.professionCategoryID :null,
            createdBy: this.createdBy ? this.createdBy : null,
            /*createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}

export class ProfessionPaginatedModel {
    status: string;
    professions: ProfessionModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.professions = response.data.map((doc: Record<string, any>) => ProfessionModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}