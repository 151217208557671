import type {BookingRequestStatus} from "@/types/util";
import {PaginationData} from "@/interfaces/general.interface";

export class MassBookingRequestModel {
    id: string;
    bankName: string;
    createdBy: string;
    parishAccountNumber: string;
    parishPriestName: string;
    parishPriestPhoneNumber: string;
    parishID: string;
    role: string;
    status: BookingRequestStatus;
    createdAt: Date;
    updatedAt: Date;

    constructor(
        id = '',
        bankName = '',
        createdAt = new Date(),
        createdBy = '',
        parishAccountNumber = '',
        parishPriestName = '',
        parishPriestPhoneNumber = '',
        parishID = '',
        role = '',
        status: BookingRequestStatus = 'pending',
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.bankName = bankName;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.parishAccountNumber = parishAccountNumber;
        this.parishPriestName = parishPriestName;
        this.parishPriestPhoneNumber = parishPriestPhoneNumber;
        this.parishID = parishID;
        this.role = role;
        this.status = status;
        this.updatedAt = updatedAt;
    }


    static fromMongo(requestData: Record<string, any>): MassBookingRequestModel {
        const data = requestData;
        return new MassBookingRequestModel(
            data._id,
            data.bankName,
            data.createdAt,
            data.createdBy,
            data.parishAccountNumber,
            data.parishPriestName,
            data.parishPriestPhoneNumber,
            data.parishID,
            data.role,
            data.status,
            data.updatedAt
        )
    }


    toMongoJSON() {
        return {
            bankName: this.bankName,
            createdBy: this.createdBy,
            parishAccountNumber: this.parishAccountNumber,
            parishPriestName: this.parishPriestName,
            parishPriestPhoneNumber: this.parishAccountNumber,
            parishID: this.parishID,
            role: this.role,
            status: this.status,
            /*createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}

export class MassBookingRequestPaginatedModel {
    status: string;
    massBookingRequests: MassBookingRequestModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = {
        status: 'success',
        data: [],
        pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}
    }) {
        this.status = response.status;
        this.massBookingRequests = response.data.map((doc: Record<string, any>) => MassBookingRequestModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}