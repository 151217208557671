// import {serverTimestamp, FieldValue} from "firebase/firestore";

export class ParishClaimModel {
    id: string;
    email: string;
    name: string;
    parishID: string;
    phone: string;
    positionAtParish: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(
        id = '',
        email = '',
        name = '',
        parishID = '',
        phone = '',
        positionAtParish = '',
        createdAt = new Date(),
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.email = email;
        this.name = name;
        this.parishID = parishID;
        this.phone = phone;
        this.positionAtParish = positionAtParish;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    static fromMongo(parishClaim: Record<string, any>){
        const data = parishClaim;
        return new ParishClaimModel(
            data._id,
            data.email,
            data.name,
            data.parishID,
            data.phone,
            data.positionAtParish,
            data.createdAt,
            data.updatedAt
        )
    }

    toMongoJSON(){
        return {
            email: this.email ? this.email : null,
            name: this.name ? this.name : null,
            parishID: this.parishID ? this.parishID : null,
            phone: this.phone ? this.phone :null,
            positionAtParish: this.positionAtParish ? this.positionAtParish : null,
            /*createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}