/* eslint-disable */
import dayjs from "dayjs";
import {PB_API_instance} from "@/config/axios_config";
import {DemoModel} from "@/models/demo";

const state = {
    loading: false,
    error: false,
};

const getters = {
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
};

const mutations = {
    setLoading(state, payload) {
        state.loading = payload;
    },
    reset(state) {
        state.loading = false;
        state.error = false;
    },
};

const actions = {
    async submitDemo({commit}, [demo, date, time]: [DemoModel, string, Record<string, number>]) {

        try {
            commit("setLoading", true);
            const demoTime = time.hours + ":" + time.minutes;
            const demoDate = dayjs(date).format("YYYY-MM-DD");

            demo.demoDate = new Date(
                dayjs(`${demoDate}${demoTime}`).format("YYYY-MM-DD HH:mm")
            );
            await PB_API_instance.post('demo', demo.toMongoJSON())
        } catch (e) {
            return Promise.reject(e);
        } finally {
            commit("setLoading", false);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
