/* eslint-disable */
import {MassBookingModel} from "@/models/massBooking";
import {PB_API_instance} from "@/config/axios_config";

const state = {
    loading: false,
};

const getters = {
    get_loading: (state) => state.loading,
};

const mutations = {
    set_loading(state, value) {
        state.loading = value;
    },
};

const actions = {
    async save_booking({commit}, booking: MassBookingModel) {
        try {
            commit('set_loading', true);
            await PB_API_instance.post('mass-booking', booking.toMongoJSON());
            return Promise.resolve({message: "Mass Booking received successfully"})
        } catch (e: any) {
            return Promise.reject({message: 'Failed to save mass booking' + ' ' + e.message})
        } finally {
            commit('set_loading', true);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
