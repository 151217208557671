/* eslint-disable */
import {PB_API_instance} from "@/config/axios_config";
import {ProfessionModel, ProfessionPaginatedModel} from "@/models/professions";

const state = {
    loading: false,
    error: false,
    professions: [] as ProfessionModel[]
};

const getters = {
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
    getProfessions: (state) => state.professions,
};

const mutations = {
    setLoading(state, payload) {
        state.loading = payload;
    },
    setProfessions(state, payload) {
        state.professions = payload;
    },
    reset(state) {
        state.loading = false;
        state.error = false;
        state.professions = [];
    },
};

const actions = {
    async fetchAllProfessionByCategoryID({commit}, {categoryID}) {
        try {
            commit('setLoading', true)
            const response = (await PB_API_instance.get(`profession/professionCategoryID?professionCategoryID=${categoryID}&itemsPerPage=1000000&page=1`)).data
            commit('setProfessions', new ProfessionPaginatedModel(response).professions);
        } catch (e: any) {
            console.log('Failed to fetch professions', e.message)
        } finally {
            commit('setLoading', false);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
