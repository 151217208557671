/* eslint-disable */
import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import auth from "@/store/modules/auth";
import massBookings from "@/store/modules/massBookings";
import user from "@/store/modules/user";
import country from "@/store/modules/country";
import contact from "@/store/modules/contactUs";
import diocese from "@/store/modules/diocese";
import parish from "@/store/modules/parish";
import demo from "@/store/modules/demo";
import claim from "@/store/modules/parishClaim";
import analytics from "@/store/modules/analytics";
import utils from "@/store/modules/utils";
import massBookingsRequest from "./modules/massBookingsRequest";
import parishioner from "@/store/modules/parishioner";
import zone from "@/store/modules/zone";
import society from "@/store/modules/society";
import profession from "@/store/modules/profession";
import professionCategory from "@/store/modules/professionCategory";
import church from "@/store/modules/church";

const ls = new SecureLS({isCompression: false});

export const store = createStore({
    modules: {
        auth,
        massBookings,
        user,
        country,
        diocese,
        parish,
        contact,
        demo,
        claim,
        analytics,
        utils,
        massBookingsRequest,
        parishioner,
        zone,
        society,
        profession,
        professionCategory,
        church,
    },
    plugins: [
        createPersistedState({
            key: "PBWebsiteV3",
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => {
                    try {
                        ls.set(key, value);
                    } catch (e) {
                        // if(confirm('We are unable to load some of the page data. Do you want to reload the page?')){
                        //     location.reload()
                        // }
                        console.warn(e);
                    }
                },
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],
});
