import type {GeoLocation, PaginationData} from "@/interfaces/general.interface";

export class ParishModel {
    id: string;
    address: string;
    bankAccount: string;
    bankName: string;
    city: string;
    confessionTimes: string;
    country: string;
    createdBy: string;
    dateCreated: Date;
    dateUpdated: Date;
    deanery: string;
    diocese: string;
    followers: string[];
    geoLocation: GeoLocation | null; //{ type: string; coordinates: number[] };
    idCardBGImage: string;
    idCardCost: number;
    images: string[];
    logo: string;
    members: string[];
    name: string;
    parishEmail: string;
    parishPaymentPlan: string;
    parishPhoneNumber: string;
    parishWebsite: string;
    priestName: string;
    priestSignature: string;
    priestPhoneNumber: string;
    primaryContactEmail: string;
    primaryContactName: string;
    primaryContactPhone: string;
    primaryContactRole: string;
    province: string;
    registrationCost: number;
    smsUnit: number;
    state: string;
    status: string;
    sundayMassTimes: string;
    trafficSource: string;
    useIdCard: boolean;
    visibility: string;
    weekdayMassTimes: string;

    constructor(
        id = '',
        address = '',
        bankAccount = '',
        bankName = '',
        city = '',
        country = '',
        confessionTimes = '',
        createdBy = '',
        diocese = '',
        dateCreated = new Date(),
        dateUpdated = new Date(),
        deanery = '',
        followers = [],
        geoLocation = null,
        images = [],
        idCardBGImage = '',
        idCardCost = 1000,
        logo = '',
        members = [],
        name = '',
        priestName = '',
        priestSignature = '',
        priestPhoneNumber = '',
        primaryContactName = '',
        primaryContactEmail = '',
        primaryContactPhone = '',
        primaryContactRole = '',
        parishEmail = '',
        parishPaymentPlan = '',
        parishPhoneNumber = '',
        parishWebsite = '',
        province = '',
        registrationCost = 0,
        smsUnit = 0,
        state = '',
        sundayMassTimes = '',
        status = '',
        trafficSource = 'website',
        visibility = '',
        useIdCard = false,
        weekdayMassTimes = '',
    ) {
        this.id = id;
        this.address = address;
        this.bankAccount = bankAccount;
        this.bankName = bankName;
        this.city = city;
        this.country = country;
        this.confessionTimes = confessionTimes;
        this.createdBy = createdBy;
        this.diocese = diocese;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.deanery = deanery;
        this.followers = followers;
        this.geoLocation = geoLocation;
        this.images = images;
        this.idCardBGImage = idCardBGImage;
        this.idCardCost = idCardCost;
        this.logo = logo;
        this.members = members;
        this.name = name;
        this.priestName = priestName;
        this.priestSignature = priestSignature;
        this.priestPhoneNumber = priestPhoneNumber;
        this.primaryContactName = primaryContactName;
        this.primaryContactEmail = primaryContactEmail;
        this.primaryContactPhone = primaryContactPhone;
        this.primaryContactRole = primaryContactRole;
        this.parishEmail = parishEmail;
        this.parishPaymentPlan = parishPaymentPlan;
        this.parishPhoneNumber = parishPhoneNumber;
        this.parishWebsite = parishWebsite;
        this.province = province;
        this.registrationCost = registrationCost;
        this.smsUnit = smsUnit;
        this.state = state;
        this.sundayMassTimes = sundayMassTimes;
        this.status = status;
        this.trafficSource = trafficSource;
        this.visibility = visibility;
        this.useIdCard = useIdCard;
        this.weekdayMassTimes = weekdayMassTimes;
    }


    static fromMongo(parishData: Record<string, any>): ParishModel {
        const data = parishData;
        return new ParishModel(
            data._id,
            data.address,
            data.bankAccount,
            data.bankName,
            data.city,
            data.country ? data.country._id : null,
            data.confessionTimes,
            data.createdBy,
            data.diocese ? data.diocese._id : null,
            data.dateCreated,
            data.dateUpdated,
            data.deanery ? data.deanery._id : null,
            data.followers,
            data.geoLocation,
            data.images,
            data.idCardBGImage,
            data.idCardCost,
            data.logo,
            data.members,
            data.name,
            data.priestName,
            data.priestSignature,
            data.priestPhoneNumber,
            data.primaryContactName,
            data.primaryContactEmail,
            data.primaryContactPhone,
            data.primaryContactRole,
            data.parishEmail,
            data.parishPaymentPlan,
            data.parishPhoneNumber,
            data.parishWebsite,
            data.province ? data.province._id : null,
            data.registrationCost,
            data.smsUnit,
            data.state,
            data.sundayMassTimes,
            data.status,
            data.trafficSource,
            data.visibility,
            data.useIdCard,
            data.weekdayMassTimes
        )
    }


    toMongoJSON() {
        return {
            address: this.address ? this.address : null,
            bankAccount: this.bankAccount ? this.bankAccount : null,
            bankName: this.bankName ? this.bankName : null,
            city: this.city ? this.city : null,
            confessionTimes: this.confessionTimes ? this.confessionTimes : null,
            country: this.country ? this.country : null,
            createdBy: this.createdBy ? this.createdBy : null,
            /*dateCreated: this.dateCreated,
             dateUpdated: this.dateUpdated,*/
            deanery: this.deanery ? this.deanery : null,
            diocese: this.diocese ? this.diocese : null,
            followers: this.followers,
            geoLocation: this.geoLocation ? this.geoLocation : null,
            idCardBGImage: this.idCardBGImage ? this.idCardBGImage : null,
            idCardCost: this.idCardCost ? this.idCardCost : 1000,
            images: this.images,
            logo: this.logo ? this.logo : null,
            members: this.members,
            name: this.name ? this.name : null,
            parishEmail: this.parishEmail ? this.parishEmail : null,
            parishPaymentPlan: this.parishPaymentPlan ? this.parishPaymentPlan : null,
            parishPhoneNumber: this.parishPhoneNumber ? this.parishPhoneNumber : null,
            parishWebsite: this.parishWebsite ? this.parishWebsite : null,
            priestName: this.priestName ? this.priestName : null,
            priestSignature: this.priestSignature ? this.priestSignature : null,
            priestPhoneNumber: this.priestPhoneNumber ? this.priestPhoneNumber : null,
            primaryContactEmail: this.primaryContactEmail ? this.primaryContactEmail : null,
            primaryContactName: this.primaryContactName ? this.primaryContactName : null,
            primaryContactPhone: this.primaryContactPhone ? this.primaryContactPhone : null,
            primaryContactRole: this.primaryContactRole ? this.primaryContactRole : null,
            province: this.province ? this.province : null,
            registrationCost: this.registrationCost ? this.registrationCost : null,
            smsUnit: this.smsUnit ? this.smsUnit : 0,
            state: this.state ? this.state : null,
            status: this.status ? this.status : null,
            sundayMassTimes: this.sundayMassTimes ? this.sundayMassTimes : null,
            trafficSource: this.trafficSource ? this.trafficSource : 'website',
            useIdCard: this.useIdCard,
            visibility: this.visibility ? this.visibility : 'hidden',
            weekdayMassTimes: this.weekdayMassTimes ? this.weekdayMassTimes : null,
        }
    }
}

export class ParishPaginatedModel {
    status: string;
    parishes: ParishModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.parishes = response.data.map((doc: Record<string, any>) => ParishModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}