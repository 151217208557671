import Swal from 'sweetalert2'
import type {AlertType} from "@/types/util";


export default function useSweetAlert() {


    function showToast(icon: AlertType = 'success', title: string, text: string) {
        Swal.fire({
            icon: icon,
            title: title,
            text: text,
            confirmButtonColor: '#009688',
        })
    }

    return {
        showToast,
    }
}