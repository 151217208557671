import {PaginationData} from "@/interfaces/general.interface";
import {CountryModel} from "@/models/countries";
import {ProvinceModel} from "@/models/provinces";

export class DioceseModel {
    id: string;
    name: string;
    countryId: CountryModel | null;
    province: ProvinceModel | null;
    bishop: string;
    isArchDiocese: boolean;
    createdBy: string;
    dateCreated: Date;
    dateUpdated: Date;

    constructor(
        id = '',
        bishop = '',
        countryId: CountryModel | null = null,
        isArchDiocese = false,
        name = '',
        province: ProvinceModel | null = null,
        createdBy = '',
        dateCreated = new Date(),
        dateUpdated = new Date()
    ) {
        this.id = id;
        this.bishop = bishop;
        this.countryId = countryId;
        this.isArchDiocese = isArchDiocese;
        this.name = name;
        this.province = province;
        this.createdBy = createdBy;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
    }


    static fromMongo(dioceseData: Record<string, any>): DioceseModel {
        const data = dioceseData;
        return new DioceseModel(
            data._id,
            data.bishop,
            data.countryId ? CountryModel.fromMongo(data.countryId) : null,
            data.isArchDiocese,
            data.name,
            data.province ? ProvinceModel.fromMongo(data.province) : null,
            data.createdBy,
            data.dateCreated,
            data.dateUpdated,
        )
    }


    toMongoJSON() {
        return {
            bishop: this.bishop ? this.bishop : null,
            countryId: this.countryId ? this.countryId.id : null,
            isArchDiocese: this.isArchDiocese,
            name: this.name ? this.name : null,
            province: this.province ? this.province.id : null,
            createdBy: this.createdBy ? this.createdBy : null,
            /*dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,*/
        }
    }
}

export class DiocesePaginatedModel {
    status: string;
    dioceses: DioceseModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = {
        status: 'success',
        data: [],
        pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}
    }) {
        this.status = response.status;
        this.dioceses = response.data.map((doc: Record<string, any>) => DioceseModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}