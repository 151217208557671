/* eslint-disable */

import {ContactUsModel} from "@/models/contactUs";
import {PB_API_instance} from "@/config/axios_config";

const state = {
    loading: false,
    error: false,
};

const getters = {
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
};

const mutations = {
    setLoading(state, payload) {
        state.loading = payload;
    },
    reset(state) {
        state.loading = false;
        state.error = false;
    },
};

const actions = {
    async submitContact({commit}, contact: ContactUsModel) {

        try {
            commit("setLoading", true);
            await PB_API_instance.post('contact-us', contact.toMongoJSON());
            return Promise.resolve(null);
        } catch (e) {
            return Promise.reject(e);
        } finally {
            commit("setLoading", false);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
