import axios, {AxiosRequestHeaders} from "axios";
import {onAuthStateChanged} from "@firebase/auth";
import {auth} from "@/config/firebase";

let pb_backend_api_url;
if (window.location.hostname === "parishbulletin.org" || window.location.hostname === "www.parishbulletin.org") {
    pb_backend_api_url = 'https://api.parishbulletin.org/'
} else {
    pb_backend_api_url ='https://staging.api.parishbulletin.org/'  //'http://localhost/'
}

const PB_Backend_Config = axios.create({

    baseURL: pb_backend_api_url,
    headers: {
        'Content-Type': 'application/json',
    }
})

// Add a request interceptor
PB_Backend_Config.interceptors.request.use(async function (config) {

    const token = await new Promise(resolve => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                resolve(user.getIdToken(true))
            } else resolve('')
        })
    });
    (config.headers as AxiosRequestHeaders).Authorization = `Bearer ${token}`;


    return config

}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


export const PB_API_instance = PB_Backend_Config;

