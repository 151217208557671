import {instanceToPlain} from "class-transformer";
import type {PaginationData} from "@/interfaces/general.interface";
import {ParishModel} from "@/models/parish";
import {ChurchModel} from "@/models/church";

export class ParishionerModel {
    id: string;
    BCC: string;
    baptismalName: string;
    createdBy: string;
    dateOfBaptism: Date | null;
    dob: string | null;
    dateOfFirstHolyCommunion: Date | null;
    dateOfConfirmation: Date | null;
    dateOfWedding: Date | null;
    dateOfIDCardIssue: Date | null;
    dateCreated: Date;
    dateUpdated: Date;
    email: string;
    familyID: string;
    isBaptised: boolean;
    isCommunicant: boolean;
    isConfirmed: boolean;
    isMarriedInChurch: boolean;
    lastDatePaid: Date | null;
    maritalStatus: string;
    occupation: string;
    otherName: string;
    parishes: ParishModel[] | string[];
    churches: ChurchModel[] | string[];
    profilePicture: string;
    profilePicturePath: string;
    professionCategory: string;
    profession: string;
    phone: string;
    parishWedded: string;
    paymentStatus: string;
    paymentMethod: string;
    paymentResponse: string | null;
    residentialAddress: string;
    regNumber: string;
    sex: string | null;
    signature: string;
    signaturePath: string;
    signatureURL: string;
    societies: string[];
    statutoryOrganization: string;
    surname: string;
    status: string;
    transactionReference: string;
    yearOfJoiningChurch: string;
    zone: string;

    constructor(
        id = '',
        BCC = '',
        baptismalName = "",
        createdBy = "",
        dateOfBaptism = null,
        dob: string | null = '1990-01-01',
        dateOfFirstHolyCommunion: Date | null = null,
        dateOfConfirmation: Date | null = null,
        dateOfWedding: Date | null = null,
        dateOfIDCardIssue: Date | null = null,
        dateCreated = new Date(),
        dateUpdated = new Date(),
        email = "",
        familyID = "",
        isBaptised = false,
        isCommunicant = false,
        isConfirmed = false,
        isMarriedInChurch = false,
        lastDatePaid = null,
        maritalStatus = 'single',
        otherName = "",
        phone = "",
        parishWedded = "",
        paymentStatus = '',
        paymentMethod = '',
        paymentResponse = null,
        occupation = "",
        parishes: string[] = [],
        churches: string[] = [],
        profilePicture = "",
        profilePicturePath = "",
        professionCategory = '',
        profession = '',
        residentialAddress = "",
        regNumber = "",
        signature = "",
        sex = "",
        signaturePath = "",
        signatureURL = '',
        societies: string[] = [],
        statutoryOrganization = "",
        status = "approved",
        surname = "",
        transactionReference = '',
        yearOfJoiningChurch = "",
        zone = "",
    ) {
        this.id = id;
        this.BCC = BCC;
        this.baptismalName = baptismalName;
        this.createdBy = createdBy;
        this.dateOfBaptism = dateOfBaptism;
        this.dob = dob;
        this.dateOfFirstHolyCommunion = dateOfFirstHolyCommunion;
        this.dateOfConfirmation = dateOfConfirmation;
        this.dateOfWedding = dateOfWedding;
        this.dateOfIDCardIssue = dateOfIDCardIssue;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.email = email;
        this.familyID = familyID;
        this.isBaptised = isBaptised;
        this.isConfirmed = isConfirmed;
        this.isCommunicant = isCommunicant;
        this.isMarriedInChurch = isMarriedInChurch;
        this.lastDatePaid = lastDatePaid;
        this.maritalStatus = maritalStatus;
        this.otherName = otherName;
        this.phone = phone;
        this.parishWedded = parishWedded;
        this.paymentStatus = paymentStatus;
        this.paymentMethod = paymentMethod;
        this.paymentResponse = paymentResponse
        this.occupation = occupation;
        this.parishes = parishes;
        this.churches = churches;
        this.profilePicture = profilePicture;
        this.profilePicturePath = profilePicturePath;
        this.professionCategory = professionCategory;
        this.profession = profession;
        this.residentialAddress = residentialAddress;
        this.regNumber = regNumber;
        this.signature = signature;
        this.sex = sex;
        this.signaturePath = signaturePath;
        this.signatureURL = signatureURL;
        this.societies = societies;
        this.statutoryOrganization = statutoryOrganization;
        this.status = status;
        this.surname = surname;
        this.transactionReference = transactionReference;
        this.yearOfJoiningChurch = yearOfJoiningChurch;
        this.zone = zone;
    }

    static fromMongo(parishionerData: Record<string, any>): ParishionerModel {

        const data = parishionerData;
        return <ParishionerModel>instanceToPlain(new ParishionerModel(
            data._id,
            data.BCC,
            data.baptismalName,
            data.createdBy,
            data.dateOfBaptism,
            data.dob,
            data.dateOfFirstHolyCommunion,
            data.dateOfConfirmation,
            data.dateOfWedding,
            data.dateOfIDCardIssue,
            data.dateCreated,
            data.dateUpdated,
            data.email,
            data.familyID,
            data.isBaptised,
            data.isCommunicant,
            data.isConfirmed,
            data.isMarriedInChurch,
            data.lastDatePaid,
            data.maritalStatus,
            data.otherName,
            data.phone,
            data.parishWedded,
            data.paymentStatus,
            data.paymentMethod,
            data.paymentResponse,
            data.occupation,
            data.parishes ? data.parishes.filter(Boolean).map((parish: Record<string, any>) => ParishModel.fromMongo(parish)) : [],
            data.churches ? data.churches.filter(Boolean).map((church: Record<string, any>) => ChurchModel.fromMongo(church)) : [],
            data.profilePicture,
            data.profilePicturePath,
            data.professionCategory,
            data.profession,
            data.residentialAddress,
            data.regNumber,
            data.signature ? data.signature : data.signatureURL ? data.signatureURL : null,
            data.sex,
            data.signaturePath,
            data.signatureURL,
            data.societies,
            data.statutoryOrganization,
            data.status,
            data.surname,
            data.transactionReference,
            data.yearOfJoiningChurch,
            data.zone
        ))
    }

    toMongoJSON() {
        return {
            BCC: this.BCC ? this.BCC : null,
            baptismalName: this.baptismalName ? this.baptismalName : null,
            createdBy: this.createdBy ? this.createdBy : null,
            dateOfBaptism: this.dateOfBaptism ? new Date(this.dateOfBaptism).toISOString() : null,
            dob: this.dob ? new Date(this.dob).toISOString() : null,
            dateOfFirstHolyCommunion: this.dateOfFirstHolyCommunion ? new Date(this.dateOfFirstHolyCommunion).toISOString() : null,
            dateOfConfirmation: this.dateOfConfirmation ? new Date(this.dateOfConfirmation).toISOString() : null,
            dateOfWedding: this.dateOfWedding ? new Date(this.dateOfWedding).toISOString() : null,
            dateOfIDCardIssue: this.dateOfIDCardIssue,
            /*dateCreated: this.dateCreated,
             dateUpdated: this.dateUpdated,*/
            email: this.email ? this.email : null,
            // firestoreID: this.id,
            familyID: this.familyID ? this.familyID : null,
            isBaptised: !!this.dateOfBaptism,
            isConfirmed: !!this.dateOfConfirmation,
            isCommunicant: !!this.dateOfFirstHolyCommunion,
            isMarriedInChurch: !!this.dateOfWedding,
            lastDatePaid: this.lastDatePaid ? new Date(this.lastDatePaid).toISOString() : null,
            maritalStatus: this.maritalStatus ? this.maritalStatus : null,
            occupation: this.occupation ? this.occupation : null,
            otherName: this.otherName ? this.otherName : null,
            parishes: this.parishes.map(parish => {
                if (typeof parish === 'string') return parish;
                else return parish?.id
            }).filter(Boolean),
            churches: this.churches.map(church => {
                if (typeof church === 'string') return church;
                else return church?.id
            }).filter(Boolean),
            profilePicture: this.profilePicture ? this.profilePicture : null,
            professionCategory: this.professionCategory ? this.professionCategory : null,
            profession: this.profession ? this.profession : null,
            phone: this.phone ? this.phone : null,
            parishWedded: this.parishWedded ? this.parishWedded : null,
            paymentStatus: this.paymentStatus ? this.paymentStatus : null,
            paymentMethod: this.paymentMethod ? this.paymentMethod : null,
            paymentResponse: this.paymentResponse,
            residentialAddress: this.residentialAddress ? this.residentialAddress : null,
            regNumber: this.regNumber ? this.regNumber : null,
            sex: this.sex ? this.sex : null,
            signature: this.signature ? this.signature : null,
            signatureURL: this.signatureURL ? this.signatureURL : null,
            societies: this.societies,
            statutoryOrganization: this.statutoryOrganization ? this.statutoryOrganization : null,
            surname: this.surname ? this.surname : null,
            status: this.status ? this.status : null,
            transactionReference: this.transactionReference ? this.transactionReference : null,
            yearOfJoiningChurch: this.yearOfJoiningChurch ? '' + this.yearOfJoiningChurch : null,
            zone: this.zone ? this.zone : null,
        }
    }
}

export class ParishionerPaginatedModel {
    status: string;
    parishioners: ParishionerModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = {
        status: 'success',
        data: [],
        pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}
    }) {
        this.status = response.status;
        this.parishioners = response.data.map((doc: Record<string, any>) => ParishionerModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}