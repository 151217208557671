<template>
  <transition name="modal">
    <div
      v-if="imgIndex !== null"
      class="vgs"
      @click="close"
    >
      <button
        type="button"
        class="vgs__close"
        @click="close"
      >
        &times;
      </button>
      <button
        v-if="isMultiple"
        type="button"
        class="vgs__prev"
        @click.stop="onPrev"
      >
        &lsaquo;
      </button>
      <div
        v-if="images"
        class="vgs__container"
        @click.stop="onNext"
      >
        <img
          class="vgs__container__img"
          :src="imageUrl"
          alt="parish image"
          @click.stop="onNext"
        >
        <slot></slot>
      </div>
      <button
        v-if="isMultiple"
        type="button"
        class="vgs__next"
        @click.stop="onNext"
      >
        &rsaquo;
      </button>
      <div
        v-if="isMultiple"
        ref="galleryRef"
        class="vgs__gallery"
      >
        <div
          v-if="images"
          class="vgs__gallery__title"
        >
          {{ imgIndex + 1 }} / {{ images.length }}
        </div>
        <div
          v-if="images"
          class="vgs__gallery__container"
          :style="{ transform: 'translate(' + galleryXPos + 'px, 0)' }"
        >
          <img
            v-for="(img, i) in images"
            :key="i"
            class="vgs__gallery__container__img"
            :src="img"
            :class="{ 'vgs__gallery__container__img--active': i === imgIndex}"
            alt="parish images"
            @click.stop="onClickThumb(img, i)"
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import {computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs, watch} from "vue";

export default defineComponent({
  /* eslint-disable */
  name: 'GallerySlideshow',
  props: {
    images : {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: ['close'],

  setup(props, context){
    const galleryRef = ref(null);
    const state = reactive({
      imgIndex: props.index,
      image: null,
      galleryXPos: 0,
      thumbnailWidth: 120
    })


    function close() {
      const eventData = {
        imgIndex: state.imgIndex
      };
      state.imgIndex = null;
      context.emit("close", eventData);
    }

   function onPrev() {
      if (state.imgIndex === null) return;
      if (state.imgIndex > 0) {
        state.imgIndex--;
      } else {
        state.imgIndex = props.images.length - 1;
      }
      updateThumbails();
    }
   function onNext() {
      if (state.imgIndex === null) return;
      if (state.imgIndex < props.images.length - 1) {
        state.imgIndex++;
      } else {
        state.imgIndex = 0;
      }
      updateThumbails();
    }

   function onClickThumb(image, index) {
      state.imgIndex = index;
      updateThumbails();
    }

   function updateThumbails() {
      if (!galleryRef.value) {
        return;
      }

      const galleryWidth = galleryRef.value.clientWidth;
      const currThumbsWidth = state.imgIndex * state.thumbnailWidth;
      const maxThumbsWidth = props.images.length * state.thumbnailWidth;
      const centerPos =
          Math.floor(galleryWidth / (state.thumbnailWidth * 2)) *
          state.thumbnailWidth;

      // Prevent scrolling of images if not needed
      if (maxThumbsWidth < galleryWidth) {
        return;
      }

      if (currThumbsWidth < centerPos) {
        state.galleryXPos = 0;
      } else if (
          currThumbsWidth >
          props.images.length * state.thumbnailWidth - galleryWidth + centerPos
      ) {
        state.galleryXPos = -(
            props.images.length * state.thumbnailWidth -
            galleryWidth -
            20
        );
      } else {
        state.galleryXPos = -(state.imgIndex * state.thumbnailWidth) + centerPos;
      }
    }

  const  imageUrl = computed(() => {
    return  props.images[state.imgIndex];
  })

  const isMultiple = computed(() => {
    return props.images.length > 1;
  })
  watch(() => props.index, (val, prev) => {
    state.imgIndex = val;

    // updateThumbails when popup
    if (prev == null && val != null) {
      nextTick(() => {
        updateThumbails();
      });
    }
  })

    onMounted(() => {
      window.addEventListener("keydown", e => {
        if (e.keyCode === 37) {
          onPrev();
        } else if (e.keyCode === 39) {
          onNext();
        } else if (e.keyCode === 27) {
          close();
        }
      });
    })
    return {
      close,
      onPrev,
      onNext,
      onClickThumb,
      imageUrl,
      isMultiple,
      galleryRef,
      ...toRefs(state)
    }
  }

})

</script>

<style scoped>
.vgs {
  transition: opacity 0.2s ease;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
}
.vgs__close {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  font-size: 25px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 999;
}
.vgs__close:focus {
  outline: 0;
}
.vgs__prev, .vgs__next {
  position: absolute;
  top: 50%;
  margin-top: -25px;
  width: 50px;
  height: 50px;
  z-index: 999;
  cursor: pointer;
  font-size: 40px;
  color: #fff;
  background-color: transparent;
  border: none;
}
.vgs__prev:focus, .vgs__next:focus {
  outline: 0;
}
.vgs__prev {
  left: 0;
}
.vgs__next {
  right: 0;
}
.vgs__container {
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  overflow: hidden;
  max-width: 100vh;
  margin: 0.5rem auto 0;
  left: 0.5rem;
  right: 0.5rem;
  height: 60vh;
  border-radius: 12px;
  background-color: #000;
}
@media (max-width: 767px) {
  .vgs__container {
    width: 100%;
    max-width: 100%;
    top: 50%;
    margin-top: -140px;
    left: 0;
    right: 0;
    border-radius: 0;
    height: 280px;
  }
}
.vgs__container__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.vgs__gallery {
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  bottom: 10px;
  margin: auto;
  max-width: 100vh;
  white-space: nowrap;
  left: 0.5rem;
  right: 0.5rem;
}
@media (max-width: 767px) {
  .vgs__gallery {
    display: none;
  }
}
.vgs__gallery__title {
  color: #fff;
  margin-bottom: 0.5rem;
}
.vgs__gallery__container {
  overflow: visible;
  display: block;
  height: 100px;
  white-space: nowrap;
  transition: all 200ms ease-in-out;
  width: 100%;
}
.vgs__gallery__container__img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  display: inline-block;
  float: none;
  margin-right: 20px;
  cursor: pointer;
  opacity: 0.6;
  border-radius: 8px;
}
.vgs__gallery__container__img--active {
  width: 100px;
  display: inline-block;
  float: none;
  opacity: 1;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}

</style>
