import type {GeoLocation, PaginationData} from "@/interfaces/general.interface";


export class ParishRequestModel {
    id: string;
    address: string;
    bankAccount: string;
    bankName: string;
    city: string;
    confessionTimes: string;
    country: string;
    createdBy: string;
    dateCreated: Date;
    dateUpdated: Date;
    deanery: string;
    diocese: string;
    followers: string[];
    geoLocation: GeoLocation | null;
    images: string[];
    isParishRep: boolean;
    logo: string;
    members: string[];
    name: string;
    reviewersComment: string;
    parishEmail: string;
    parishPaymentPlan: string;
    parishPhoneNumber: string;
    primaryContactEmail: string;
    primaryContactName: string;
    primaryContactPhone: string;
    primaryContactRole: string;
    priestPhoneNumber: string;
    priestName: string;
    parishWebsite: string;
    province: string;
    smsUnit: number;
    state: string;
    status: string;
    sundayMassTimes: string;
    trafficSource: string;
    visibility: string;
    weekdayMassTimes: string;

    constructor(
        id = '',
        address = '',
        bankAccount = '',
        bankName = '',
        city = '',
        state = '',
        confessionTimes = '',
        country = '',
        createdBy = '',
        dateCreated = new Date(),
        dateUpdated = new Date(),
        deanery = '',
        diocese = '',
        followers = [],
        geoLocation = null,
        images = [],
        isParishRep = false,
        logo = '',
        members = [],
        name = '',
        reviewersComment = '',
        parishEmail = '',
        parishPaymentPlan = '',
        parishPhoneNumber = '',
        primaryContactEmail = '',
        primaryContactName = '',
        primaryContactPhone = '',
        primaryContactRole = '',
        priestPhoneNumber = '',
        priestName = '',
        parishWebsite = '',
        province = '',
        smsUnit = 0,
        status = 'pending',
        sundayMassTimes = '',
        trafficSource = 'web',
        visibility = 'visible',
        weekdayMassTimes = '',
    ) {
        this.id = id;
        this.address = address;
        this.bankAccount = bankAccount;
        this.bankName = bankName;
        this.city = city;
        this.state = state;
        this.confessionTimes = confessionTimes;
        this.country = country;
        this.createdBy = createdBy;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.deanery = deanery;
        this.diocese = diocese;
        this.followers = followers;
        this.geoLocation = geoLocation;
        this.images = images;
        this.isParishRep = isParishRep;
        this.logo = logo;
        this.members = members;
        this.name = name;
        this.reviewersComment = reviewersComment;
        this.parishEmail = parishEmail;
        this.parishPaymentPlan = parishPaymentPlan;
        this.parishPhoneNumber = parishPhoneNumber;
        this.primaryContactEmail = primaryContactEmail;
        this.primaryContactName = primaryContactName;
        this.primaryContactPhone = primaryContactPhone;
        this.primaryContactRole = primaryContactRole;
        this.priestPhoneNumber = priestPhoneNumber;
        this.priestName = priestName;
        this.parishWebsite = parishWebsite;
        this.province = province;
        this.smsUnit = smsUnit;
        this.status = status;
        this.sundayMassTimes = sundayMassTimes;
        this.trafficSource = trafficSource;
        this.visibility = visibility;
        this.weekdayMassTimes = weekdayMassTimes;
    }


    static fromMongo(parishRequestData: Record<string, any>): ParishRequestModel {
        const data = parishRequestData;
        return new ParishRequestModel(
            data._id,
            data.address,
            data.bankAccount,
            data.bankName,
            data.city,
            data.state,
            data.confessionTimes,
            data.country,
            data.createdBy,
            data.dateCreated,
            data.dateUpdated,
            data.deanery,
            data.diocese,
            data.followers,
            data.geoLocation,
            data.images,
            data.isParishRep,
            data.logo,
            data.members,
            data.name,
            data.reviewersComment,
            data.parishEmail,
            data.parishPaymentPlan,
            data.parishPhoneNumber,
            data.primaryContactEmail,
            data.primaryContactName,
            data.primaryContactPhone,
            data.primaryContactRole,
            data.priestPhoneNumber,
            data.priestName,
            data.parishWebsite,
            data.province,
            data.smsUnit,
            data.status,
            data.sundayMassTimes,
            data.trafficSource,
            data.visibility,
            data.weekdayMassTimes
        )
    }


    toMongoJSON() {
        return {
            address: this.address ? this.address : null,
            bankAccount: this.bankAccount ? this.bankAccount : null,
            bankName: this.bankName ? this.bankName : null,
            city: this.city ? this.city : null,
            confessionTimes: this.confessionTimes ? this.confessionTimes : null,
            country: this.country ? this.country : null,
            createdBy: this.createdBy ? this.createdBy : null,
            /*dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,*/
            deanery: this.deanery ? this.deanery : null,
            diocese: this.diocese ? this.diocese : null,
            followers: this.followers,
            geoLocation: this.geoLocation ? this.geoLocation : null,
            images: this.images,
            isParishRep: this.isParishRep,
            logo: this.images.length > 0 ? this.images[0] : null,
            members: this.members,
            name: this.name ? this.name : null,
            reviewersComment: this.reviewersComment ? this.reviewersComment : null,
            parishEmail: this.parishEmail ? this.parishEmail : null,
            parishPaymentPlan: this.parishPaymentPlan ? this.parishPaymentPlan : null,
            parishPhoneNumber: this.parishPhoneNumber ? this.parishPhoneNumber : null,
            primaryContactEmail: this.primaryContactEmail ? this.primaryContactEmail : null,
            primaryContactName: this.primaryContactName ? this.primaryContactName : null,
            primaryContactPhone: this.primaryContactPhone ? this.primaryContactPhone : null,
            primaryContactRole: this.primaryContactRole ? this.primaryContactRole : null,
            priestPhoneNumber: this.priestPhoneNumber ? this.priestPhoneNumber : null,
            priestName: this.priestName ? this.priestName : null,
            parishWebsite: this.parishWebsite ? this.parishWebsite : null,
            province: this.province ? this.province : null,
            smsUnit: this.smsUnit ? this.smsUnit : 0,
            state: this.state ? this.state : null,
            status: this.status ? this.status : null,
            sundayMassTimes: this.sundayMassTimes ? this.sundayMassTimes : null,
            trafficSource: this.trafficSource ? this.trafficSource : null,
            visibility: this.visibility ? this.visibility : null,
            weekdayMassTimes: this.weekdayMassTimes ? this.weekdayMassTimes : null
        }
    }
}

export class ParishRequestPaginatedModel {
    status: string;
    parishRequests: ParishRequestModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = {
        status: 'success',
        data: [],
        pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}
    }) {
        this.status = response.status;
        this.parishRequests = response.data.map((doc: Record<string, any>) => ParishRequestModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}


