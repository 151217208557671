import {createApp, h} from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store'

import SuccessModal from "@/components/popups/SuccessModal.vue";
import ErrorModal from "@/components/popups/ErrorModal.vue";

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import Toast, {PluginOptions} from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


const options: PluginOptions = {
    // You can set your default options here
};

import VueGoogleMaps from '@fawmi/vue-google-maps'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
// Set the components prop default to return our fresh components
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
vSelect.props.components.default = () => ({
    Deselect: {
        render: () => h('span', '❌'),
    },
    OpenIndicator: {
        render: () => h('span', '🔽'),
    },
});


createApp(App)
    .use(LoadingPlugin, {loader: 'dots', color: '#218d80'})
    .component("v-select", vSelect)
    .component('success-modal', SuccessModal)
    .component('error-modal', ErrorModal)
    .use(Toast, options)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyDs7xnM59IzvM0jEFs7Eq-a4p0dlZkrgek',
            /*libraries: 'places',
            installComponents: true*/
        }
    }).use(store).use(router).mount('#app')
