/* eslint-disable */
import {PB_API_instance} from "@/config/axios_config";
import {ProfessionCategoriesPaginatedModel, ProfessionCategoryModel} from "@/models/professionCategories";

const state = {
    loading: false,
    error: false,
    professionCategories: [] as ProfessionCategoryModel[]
};

const getters = {
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
    getProfessionCategories: (state) => state.professionCategories,
};

const mutations = {
    setLoading(state, payload) {
        state.loading = payload;
    },
    setProfessionCategories(state, payload) {
        state.professionCategories = payload;
    },
    reset(state) {
        state.loading = false;
        state.error = false;
    },
};

const actions = {
    async fetchAllProfessionCategories({commit}) {
        try {
            commit('setLoading', true);
            const response: Record<string, any> = (await PB_API_instance.get(`profession-category?itemsPerPage=100000&page=1`)).data;
            commit('setProfessionCategories', new ProfessionCategoriesPaginatedModel(response).professionCategories)
        } catch (e: any) {
            console.log('Failed to fetch profession categories', e.message);
        } finally {
            commit('setLoading', false);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
