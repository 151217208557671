/* eslint-disable */
import {PB_API_instance} from "@/config/axios_config";
import {ChurchModel} from "@/models/church";
import {UserModel} from "@/models/user";
import useFuncUtil from "@/composables/useFuncUtil";

const state = {
    loading: false,
    error: false,
};

const getters = {
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
};

const mutations = {
    setLoading(state, payload) {
        state.loading = payload;
    },
    reset(state) {
        state.loading = false;
        state.error = false;
    },
};

const actions = {

    async save_church_to_pb({commit}, [primaryContactRole, currentSoftware, whatMattersMost, address, city, country, churchName, estimatedPopulation, isCatholicChurch]: [string, string, string[], string, string, string, string, number | null, boolean]) {
        try {
            commit('setLoading', true);
            const newChurch = new ChurchModel('', address, '', '', city, country, '', '', currentSoftware, '', new Date(), new Date(), '', estimatedPopulation, [], null, [], '', 1000, isCatholicChurch, '', '', [], churchName, '', '', '', '', '', '', primaryContactRole, '', '', '', '', '', 0, 0, '',  '', 'pending', 'website', 'visible', false, '', whatMattersMost)
            const response: Record<string, any> = (await PB_API_instance.post("church", newChurch.toMongoJSON())).data;
            return Promise.resolve({
                id: response._id,
                message: "Church Detail Successfully Saved",
            });
        } catch (e: any) {
            return Promise.reject({message: e.response ? e.response.data.message : e.message});
        } finally {
            commit('setLoading', false)
        }
    },
    async update_church_on_pb({commit}, [churchID, userDetails]: [string, UserModel]) {
        try {
            const {encodeBase64String} = useFuncUtil();
            commit('setLoading', true);

            await PB_API_instance.patch(`church/${churchID}`, {
                createdBy: userDetails.id,
                primaryContactName: `${userDetails.surname} ${userDetails.firstName}`,
                primaryContactEmail: userDetails.email,
                primaryContactPhone: userDetails.phone,
                invitationLink: `https://parishbulletin.org/church-member-registration?id=${encodeBase64String(churchID)}`
            });

            return Promise.resolve({message: "Successfully updated Church details"});
        } catch (e: any) {
            return Promise.reject({message: `Error updating Church detail: ${e.response ? e.response.data.message : e.message}`});
        } finally {
            commit('setLoading', false)
        }
    },

    async fetch_church_by_id({commit, dispatch}, [churchID]: [string]) {
        try {
            commit('setLoading', true);
            const response: Record<string, any> | null = (await PB_API_instance.get(`church/${churchID}`)).data;
            if (response) {
                return Promise.resolve(ChurchModel.fromMongo(response));
            } else throw new Error('Failed to fetch Church details')
        } catch (e: any) {
            return Promise.reject({message: `Error Fetching Church detail: ${e.response ? e.response.data.message : e.message}`});
        }finally {
            commit('setLoading', false);
        }
    },

    async getChurchByID({commit}, {churchID}) {
        try {
            commit("setLoading", true);
            const churchDoc: Record<string, any> | null = (await PB_API_instance.get(`church/${churchID}`)).data;
            if (churchDoc) {
                return Promise.resolve(ChurchModel.fromMongo(churchDoc));
            }else return Promise.resolve(null)
        } catch (e) {
            return Promise.resolve(null);
        } finally {
            commit("setLoading", false);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
