<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="errorModal" data-backdrop="static" data-keyboard="false" tabindex="-1"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body position-relative">
            <div class="position-absolute top-5 end-5">
              <button style="opacity: 1 !important;" type="button" class="close text-dark" data-dismiss="modal"
                      aria-label="Close"></button>
            </div>
            <div class="d-flex justify-content-center">
              <div class="text-center">
                <img src="/assets/images/failure-icon.png" alt="success icon">
                <div class="success-header single-line-ellipsis" id="custom-modal-title"></div>
              </div>
            </div>

            <div style="display: grid !important;" class="col-10 mx-auto">
              <p class="text-center multiline-ellipsis" id="custom-modal-message"></p>
            </div>
            <div style="display: grid !important;" class="col-10 mx-auto">
              <button class="btn btn-success bg-transparent btn-lg" type="button" data-dismiss="modal">OK
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'ErrorModal',
})
</script>

<style scoped>
.success-header {
  font-family: Rubik, sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 26.07px;
  text-align: center;
  color: rgba(204, 84, 74, 1);
}

.btn-success {
  border: 2px solid #009688;
  box-shadow: 0 25px 40px 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(255, 255, 255, 1);

  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #009688;
}

.btn-success:hover {
  background-color: #009688 !important;
  color: rgba(255, 255, 255, 1) !important;
}

.top-5 {
  top: 3% !important;
}

.end-5 {
  right: 3% !important;
}

.single-line-ellipsis {
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}
</style>