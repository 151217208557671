/* eslint-disable */
import {ParishionerModel} from "@/models/parishioner";
import {PB_API_instance} from "@/config/axios_config";
import {FamilyModel} from "@/models/family";

const state = {
    loading: false,
};

const getters = {
    get_loading: (state) => state.loading,
};

const mutations = {
    set_loading(state, value) {
        state.loading = value;
    },
};

const actions = {
    async fetch_parishioner_info({commit}, [parishionerID]: [string]) {

        try {
            commit("set_loading", true);
            const response: Record<string, any> | null = (await PB_API_instance.get(`parishioner/get-parishioner-by-qr-code/${parishionerID}`)).data;
            if (response) {
                return Promise.resolve(ParishionerModel.fromMongo(response));
            } else return Promise.resolve(null);
        } catch (e) {
            return Promise.reject({message: 'Failed to fetch dioceses'})
        } finally {
            commit("set_loading", false);
        }
    },

    async addParishioner({commit, dispatch, rootGetters}, [_parishionerData, pictureFile, churchName, churchID]: [ParishionerModel, File | null, string, string]) {
        try {
            commit('set_loading', true);
            const new_parishioner_data = _parishionerData;
            new_parishioner_data.createdBy = rootGetters['user/get_user'].id;
            new_parishioner_data.churches = [churchID];

            const response: Record<string, any> = (await PB_API_instance.post('parishioner', new_parishioner_data.toMongoJSON())).data;
            const new_parishionerID = response._id;
            const update_object = {
                profilePicture: null,
                familyID: null
            } as Record<string, any | null>;

            // upload profile picture
            if (pictureFile) {
                update_object.profilePicture = await dispatch('utils/uploadImageToFirebase', {
                    file: pictureFile,
                    folder: `parishioners_photo/${churchName}/${new_parishioner_data.surname}`
                }, {root: true});
            }


            if (new_parishioner_data.sex === 'male' && new_parishioner_data.maritalStatus === 'married') {
                // new family
                const new_family_obj = new FamilyModel();
                new_family_obj.familyHeadPhone = new_parishioner_data.phone;
                new_family_obj.name = `${new_parishioner_data.surname ? new_parishioner_data.surname : ""}  ${new_parishioner_data.otherName ? new_parishioner_data.otherName : ""}  ${new_parishioner_data.baptismalName ? new_parishioner_data.baptismalName : ""}`;
                new_family_obj.members.push(new_parishionerID);
                new_family_obj.createdBy = rootGetters['user/get_user'].id;
                new_family_obj.churchID = churchID;
                const response: Record<string, any> = (await PB_API_instance.post('family', new_family_obj.toMongoJSON())).data;
                update_object.familyID = response._id;
            }

            for await (const [key, value] of Object.entries(update_object)) {
                if (value) {
                    await PB_API_instance.patch(`parishioner/${new_parishionerID}`, {
                        [key]: value
                    })
                }
            }
            // isFromSuperAdminDashboard ? await fetchAllParishioners() : churchID ? await fetchAllFamiliesInChurch(churchID) : await loadParishParishioners(parishId);
            return Promise.resolve({context: 'success', message: "Member Registration Successful"});
        } catch (e: any) {
            return Promise.reject({context: 'error', message: e.response ? e.response.data.message : e.message});
        } finally {
            commit('set_loading', false);
        }
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
