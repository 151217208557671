import type {GeoLocation} from "@/interfaces/general.interface";

export class ChurchModel {
    id: string;
    address: string;
    bankAccount: string;
    bankName: string;
    city: string;
    confessionTimes: string;
    country: string;
    createdBy: string;
    currentSoftware: string;
    dateCreated: Date;
    dateUpdated: Date;
    deanery: string;
    diocese: string;
    estimatedPopulation: number | null;
    followers: string[];
    geoLocation: GeoLocation | null; //{ type: string; coordinates: number[] };
    idCardBGImage: string;
    idCardCost: number;
    images: string[];
    isCatholicChurch: boolean;
    invitationLnk: string;
    logo: string;
    members: string[];
    name: string;
    churchEmail: string;
    churchPaymentPlan: string;
    churchPhoneNumber: string;
    churchWebsite: string;
    pastorName: string;
    pastorSignature: string;
    pastorPhoneNumber: string;
    primaryContactEmail: string;
    primaryContactName: string;
    primaryContactPhone: string;
    primaryContactRole: string;
    province: string;
    registrationCost: number;
    smsUnit: number;
    state: string;
    status: string;
    sundayMassTimes: string;
    trafficSource: string;
    useIdCard: boolean;
    visibility: string;
    weekdayMassTimes: string;
    whatMattersMost: string[];

    constructor(
        id = '',
        address = '',
        bankAccount = '',
        bankName = '',
        city = '',
        country = '',
        confessionTimes = '',
        createdBy = '',
        currentSoftware = '',
        diocese = '',
        dateCreated = new Date(),
        dateUpdated = new Date(),
        deanery = '',
        estimatedPopulation: null | number = null,
        followers = [],
        geoLocation = null,
        images = [],
        idCardBGImage = '',
        idCardCost = 1000,
        isCatholicChurch = false,
        invitationLnk = '',
        logo = '',
        members = [],
        name = '',
        pastorName = '',
        pastorSignature = '',
        pastorPhoneNumber = '',
        primaryContactName = '',
        primaryContactEmail = '',
        primaryContactPhone = '',
        primaryContactRole = '',
        churchEmail = '',
        churchPaymentPlan = '',
        churchPhoneNumber = '',
        churchWebsite = '',
        province = '',
        registrationCost = 0,
        smsUnit = 0,
        state = '',
        sundayMassTimes = '',
        status = '',
        trafficSource = 'website',
        visibility = '',
        useIdCard = false,
        weekdayMassTimes = '',
        whatMattersMost: string[] = [],
    ) {
        this.id = id;
        this.address = address;
        this.bankAccount = bankAccount;
        this.bankName = bankName;
        this.city = city;
        this.country = country;
        this.confessionTimes = confessionTimes;
        this.createdBy = createdBy;
        this.currentSoftware = currentSoftware;
        this.diocese = diocese;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
        this.deanery = deanery;
        this.estimatedPopulation = estimatedPopulation;
        this.followers = followers;
        this.geoLocation = geoLocation;
        this.images = images;
        this.idCardBGImage = idCardBGImage;
        this.idCardCost = idCardCost;
        this.isCatholicChurch = isCatholicChurch;
        this.invitationLnk = invitationLnk;
        this.logo = logo;
        this.members = members;
        this.name = name;
        this.pastorName = pastorName;
        this.pastorSignature = pastorSignature;
        this.pastorPhoneNumber = pastorPhoneNumber;
        this.primaryContactName = primaryContactName;
        this.primaryContactEmail = primaryContactEmail;
        this.primaryContactPhone = primaryContactPhone;
        this.primaryContactRole = primaryContactRole;
        this.churchEmail = churchEmail;
        this.churchPaymentPlan = churchPaymentPlan;
        this.churchPhoneNumber = churchPhoneNumber;
        this.churchWebsite = churchWebsite;
        this.province = province;
        this.registrationCost = registrationCost;
        this.smsUnit = smsUnit;
        this.state = state;
        this.sundayMassTimes = sundayMassTimes;
        this.status = status;
        this.trafficSource = trafficSource;
        this.visibility = visibility;
        this.useIdCard = useIdCard;
        this.weekdayMassTimes = weekdayMassTimes;
        this.whatMattersMost = whatMattersMost;
    }


    static fromMongo(churchData: Record<string, any>): ChurchModel {
        const data = churchData;
        return new ChurchModel(
            data._id,
            data.address,
            data.bankAccount,
            data.bankName,
            data.city,
            data.country,
            data.confessionTimes,
            data.createdBy,
            data.currentSoftware,
            data.diocese,
            data.dateCreated,
            data.dateUpdated,
            data.deanery,
            data.estimatedPopulation,
            data.followers,
            data.geoLocation,
            data.images,
            data.idCardBGImage,
            data.idCardCost,
            data.isCatholicChurch,
            data.invitationLnk,
            data.logo,
            data.members,
            data.name,
            data.pastorName,
            data.pastorSignature,
            data.pastorPhoneNumber,
            data.primaryContactName,
            data.primaryContactEmail,
            data.primaryContactPhone,
            data.primaryContactRole,
            data.churchEmail,
            data.churchPaymentPlan,
            data.churchPhoneNumber,
            data.churchWebsite,
            data.province,
            data.registrationCost,
            data.smsUnit,
            data.state,
            data.sundayMassTimes,
            data.status,
            data.trafficSource,
            data.visibility,
            data.useIdCard,
            data.weekdayMassTimes,
            data.whatMattersMost
        )
    }


    toMongoJSON() {
        return {
            address: this.address ? this.address : null,
            bankAccount: this.bankAccount ? this.bankAccount : null,
            bankName: this.bankName ? this.bankName : null,
            city: this.city ? this.city : null,
            confessionTimes: this.confessionTimes ? this.confessionTimes : null,
            country: this.country ? this.country : null,
            createdBy: this.createdBy ? this.createdBy : null,
            currentSoftware: this.currentSoftware ? this.currentSoftware : null,
            /*dateCreated: this.dateCreated,
             dateUpdated: this.dateUpdated,*/
            deanery: this.deanery ? this.deanery : null,
            diocese: this.diocese ? this.diocese : null,
            estimatedPopulation: this.estimatedPopulation ? this.estimatedPopulation : null,
            followers: this.followers,
            geoLocation: this.geoLocation ? this.geoLocation : null,
            idCardBGImage: this.idCardBGImage ? this.idCardBGImage : null,
            idCardCost: this.idCardCost ? this.idCardCost : 1000,
            isCatholicChurch: this.isCatholicChurch,
            invitationLnk: this.invitationLnk,
            images: this.images,
            logo: this.logo ? this.logo : null,
            members: this.members,
            name: this.name ? this.name : null,
            churchEmail: this.churchEmail ? this.churchEmail : null,
            churchPaymentPlan: this.churchPaymentPlan ? this.churchPaymentPlan : null,
            churchPhoneNumber: this.churchPhoneNumber ? this.churchPhoneNumber : null,
            churchWebsite: this.churchWebsite ? this.churchWebsite : null,
            pastorName: this.pastorName ? this.pastorName : null,
            pastorSignature: this.pastorSignature ? this.pastorSignature : null,
            pastorPhoneNumber: this.pastorPhoneNumber ? this.pastorPhoneNumber : null,
            primaryContactEmail: this.primaryContactEmail ? this.primaryContactEmail : null,
            primaryContactName: this.primaryContactName ? this.primaryContactName : null,
            primaryContactPhone: this.primaryContactPhone ? this.primaryContactPhone : null,
            primaryContactRole: this.primaryContactRole ? this.primaryContactRole : null,
            province: this.province ? this.province : null,
            registrationCost: this.registrationCost ? this.registrationCost : null,
            smsUnit: this.smsUnit ? this.smsUnit : 0,
            state: this.state ? this.state : null,
            status: this.status ? this.status : null,
            sundayMassTimes: this.sundayMassTimes ? this.sundayMassTimes : null,
            trafficSource: this.trafficSource ? this.trafficSource : 'website',
            useIdCard: this.useIdCard,
            visibility: this.visibility ? this.visibility : 'visible',
            weekdayMassTimes: this.weekdayMassTimes ? this.weekdayMassTimes : null,
            whatMattersMost: this.whatMattersMost,
        }
    }
}