import dayjs from "dayjs";
export function validateEmail (mail) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)
}


export default {
    methods: {
        get_readable_date(store_timestamp) {
            if (store_timestamp) {
                const _date = new Date(0)
                _date.setSeconds(store_timestamp.seconds)
                if (_date.getTime() === _date.getTime()) {
                    return _date.toLocaleString(undefined, {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                }
            }
            return 'Unable to read timestamp'
        },
        get_readable_dates(_dates) {
            const _date = new Date(_dates)
            return _date.toLocaleString(undefined, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})
        },
        get_shorthand_dates(store_timestamp) {

            if (store_timestamp) {
                const _date = new Date(0)
                _date.setSeconds(store_timestamp.seconds)
                if (_date.getTime() === _date.getTime()) {
                    return _date.toLocaleString(undefined, {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                    })
                }
            }
            return 'Unable to read timestamp'
        },
        get_readable_time(store_timestamp) {
            const _date = new Date(0)
            _date.setSeconds(store_timestamp.seconds)
            if (_date.getTime() === _date.getTime()) {
                return _date.toLocaleString(undefined, {hour: 'numeric', minute: 'numeric', hour12: true})
            }
            return 'Unable to read timestamp'
        },
        get_readable_datetime(store_timestamp) {
            const _date = new Date(0)
            _date.setSeconds(store_timestamp.seconds)
            if (_date.getTime() === _date.getTime()) {
                return _date.toLocaleString(undefined, {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })
            }
            return 'Unable to read timestamp'
        },
        formatDate(date){
           return dayjs(new Date(date)).format('ddd, MMM D, YYYY [|] h:mm A')
        }

    }
}
