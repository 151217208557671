import {PaginationData} from "@/interfaces/general.interface";

export class JobApplicationModel {
    id: string;
    bestFitForThisJob: string;
    createdAt: Date;
    email: string;
    facebookProfileURL: string;
    fullName: string;
    instagramProfileURL: string;
    isCatholic: boolean;
    linkedInProfileURL: string;
    phone: string;
    resume: string[];
    updatedAt: Date;

    constructor(
        id = '',
        bestFitForThisJob = '',
        email = '',
        facebookProfileURL = '',
        fullName = '',
        instagramProfileURL = '',
        isCatholic = false,
        linkedInProfileURL = '',
        phone = '',
        resume = [],
        createdAt = new Date(Date.now()),
        updatedAt = new Date(Date.now()),
    ) {
        this.id = id;
        this.bestFitForThisJob = bestFitForThisJob;
        this.email = email;
        this.facebookProfileURL = facebookProfileURL;
        this.fullName = fullName;
        this.instagramProfileURL = instagramProfileURL;
        this.isCatholic = isCatholic;
        this.linkedInProfileURL = linkedInProfileURL;
        this.phone = phone;
        this.resume = resume;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }


    static fromMongo(application: Record<string, any>): JobApplicationModel {
        const data = application;
        return new JobApplicationModel(data._id, data.bestFitForThisJob, data.email, data.facebookProfileURL, data.fullName, data.instagramProfileURL, data.isCatholic, data.linkedInProfileURL, data.phone, data.resume, data.createdAt, data.updatedAt)
    }

    toMongoJSON() {
        return {
            bestFitForThisJob: this.bestFitForThisJob,
            email: this.email,
            facebookProfileURL: this.facebookProfileURL,
            fullName: this.fullName,
            instagramProfileURL: this.instagramProfileURL,
            isCatholic: this.isCatholic,
            linkedInProfileURL: this.linkedInProfileURL,
            phone: this.phone,
            resume: this.resume,
            /*createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}

export class JobApplicationPaginatedModel {
    status: string;
    jobApplications: JobApplicationModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.jobApplications = response.data.map((doc: Record<string, any>) => JobApplicationModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}