import {instanceToPlain} from "class-transformer";
import type {PaginationData} from "@/interfaces/general.interface";

export class SocietyModel {
    id: string;
    contact: string;
    name: string;
    logo: string;
    meetingTimes: string;
    meetingVenue: string;
    parishID: string;
    churchID: string;
    president: string;
    createdBy: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(
        id = '',
        contact = '',
        name = '',
        logo = '',
        meetingTimes = '',
        meetingVenue = '',
        parishID = '',
        churchID = '',
        president = '',
        createdBy = '',
        createdAt = new Date(),
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.contact = contact;
        this.name = name;
        this.logo = logo;
        this.meetingTimes = meetingTimes;
        this.meetingVenue = meetingVenue;
        this.parishID = parishID;
        this.churchID = churchID;
        this.president = president;
        this.createdBy = createdBy;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }

    static fromMongo(societyData: Record<string, any>): SocietyModel {
        const data = societyData;
        return <SocietyModel>instanceToPlain(new SocietyModel(
            data._id,
            data.contact,
            data.name,
            data.logo,
            data.meetingTimes,
            data.meetingVenue,
            data.parishID ? data.parishID._id : null,
            data.churchID ? data.churchID._id : null,
            data.president,
            data.createdBy,
            data.createdAt,
            data.updatedAt,
        ))
    }

    toMongoJSON() {
        return {
            contact: this.contact ? this.contact : null,
            name: this.name ? this.name : null,
            logo: this.logo ? this.logo : null,
            meetingTimes: this.meetingTimes ? this.meetingTimes : null,
            meetingVenue: this.meetingVenue ? this.meetingVenue : null,
            parishID: this.parishID ? this.parishID : null,
            churchID: this.churchID ? this.churchID : null,
            president: this.president ? this.president : null,
            createdBy: this.createdBy ? this.createdBy : null,
            createdAt: new Date(this.createdAt).toISOString(),
            updatedAt: new Date(this.updatedAt).toISOString(),
        }
    }
}

export class SocietyPaginatedModel {
    status: string;
    societies: SocietyModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.societies = response.data.map((doc: Record<string, any>) => SocietyModel.fromMongo(doc));
        this.pagination = response.pagination;
    }
}