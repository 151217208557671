import {PaginationData} from "@/interfaces/general.interface";

export class MassBookingModel {
    id: string;
    amountPaid: number;
    createdBy: string;
    currency: string;
    days: Date[];
    email: string;
    intention: string;
    name: string;
    numberOfDays: number;
    parishID: string;
    paymentMethod: string;
    paymentResponse: Record<string, any> | null;
    paymentStatus: string;
    phoneNumber: string;
    transactionReference: string;
    withThanksGivingProcession: boolean;
    createdAt: Date;
    updatedAt: Date;
    constructor(
        id = '',
        amountPaid = 0,
        createdBy = '',
        currency = '',
        days = [],
        email = '',
        intention = '',
        name = '',
        numberOfDays = 0,
        parishID = '',
        paymentMethod = 'cash',
        paymentStatus = 'not paid',
        phoneNumber = '',
        withThanksGivingProcession = false,
        paymentResponse = null,
        transactionReference = '',
        createdAt = new Date(),
        updatedAt = new Date(),
    ) {
        this.id = id;
        this.amountPaid = amountPaid;
        this.createdBy = createdBy;
        this.currency = currency;
        this.days = days;
        this.email = email;
        this.intention = intention;
        this.name = name;
        this.numberOfDays = numberOfDays;
        this.parishID = parishID;
        this.paymentMethod = paymentMethod;
        this.paymentStatus = paymentStatus;
        this.phoneNumber = phoneNumber;
        this.withThanksGivingProcession = withThanksGivingProcession;
        this.paymentResponse = paymentResponse;
        this.transactionReference = transactionReference;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }


    static fromMongo(massBookingData: Record<string, any>): MassBookingModel {
        const data = massBookingData;
        return new MassBookingModel(
            data._id,
            data.amountPaid,
            data.createdBy,
            data.currency,
            data.days,
            data.email,
            data.intention,
            data.name,
            data.numberOfDays,
            data.parishID ? data.parishID._id : null,
            data.paymentMethod,
            data.paymentStatus,
            data.phoneNumber,
            data.withThanksGivingProcession,
            data.paymentResponse,
            data.transactionReference,
            data.createdAt,
            data.updatedAt
        )
    }


    toMongoJSON() {
        return {
            amountPaid: +this.amountPaid,
            createdBy: this.createdBy ? this.createdBy : null,
            currency: this.currency ? this.currency : null,
            days: this.days.length > 0 ? this.days.map(date => new Date(date).toISOString()) : [],
            email: this.email ? this.email : null,
            intention: this.intention ? this.intention : null,
            name: this.name ? this.name : null,
            numberOfDays: this.days.length,
            parishID: this.parishID ? this.parishID : null,
            paymentMethod: this.paymentMethod ? this.paymentMethod : null,
            paymentResponse: this.paymentResponse ? JSON.stringify(this.paymentResponse) : null,
            paymentStatus: this.paymentStatus ? this.paymentStatus : null,
            phoneNumber: this.phoneNumber ? this.phoneNumber : null,
            transactionReference: this.transactionReference ? this.transactionReference : null,
            withThanksGivingProcession: this.withThanksGivingProcession,
           /* createdAt: this.createdAt,
            updatedAt: this.updatedAt,*/
        }
    }
}

export class MassBookingPaginatedModel {
    status: string;
    massBookings: MassBookingModel[];
    pagination: PaginationData;

    constructor(response: Record<string, any> = { status: 'success', data: [], pagination: {count: 0, previousPage: false, nextPage: true, currentPage: 1}}) {
        this.status = response.status;
        this.massBookings = response.data.map((doc: Record<string, any>) => MassBookingModel.fromMongo(doc));
        this.pagination = response.pagination;
    }

}