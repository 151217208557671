/* eslint-disable */
import axios from "axios";
import {PB_API_instance} from "@/config/axios_config";
import {ParishModel, ParishPaginatedModel} from "@/models/parish";
import {ParishProgramModel, ParishProgramPaginatedModel} from "@/models/parishPrograms";
import {ParishRequestModel} from "@/models/parishRequest";
import {ChurchModel} from "@/models/church";
import {UserModel} from "@/models/user";
import useFuncUtil from "@/composables/useFuncUtil";

const state = {
    loading: false,
    error: false,
    parishes: [] as ParishModel[],
    userParish: new ParishModel(),
    nearByParishes: [] as ParishModel[],
    selectedParish: new ParishModel(),
    parishPrograms: [] as ParishProgramModel[],
    center_point: {lat: 0, lng: 0},
};

const getters = {
    getLoading: (state) => state.loading,
    getError: (state) => state.error,
    getNearByParishes: (state) => state.nearByParishes,
    getSelectedParish: (state) => state.selectedParish,
    getParishPrograms: (state) => state.parishPrograms,
    getCenterPoint: (state) => state.center_point,
    getUserParish: (state) => state.userParish,
};

const mutations = {
    setNearByParishes(state, payload) {
        state.nearByParishes = payload;
    },
    setSelectedParish(state, payload) {
        state.selectedParish = payload;
    },
    setUserParish(state, payload) {
        state.userParish = payload;
    },
    setParishPrograms(state, payload) {
        state.parishPrograms = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setCenterPoint(state, payload) {
        state.center_point = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    reset(state) {
        state.loading = false;
        state.error = false;
        state.nearByParishes.length = 0;
        state.parishPrograms.length = 0;
        state.center_point = {lat: 0, lng: 0};
    },
};

const actions = {
    async fetchParishesNearBy({state, commit}, [lat, lng]: [number, number]) {
        try {
            commit("setLoading", true);
            if (lat && lng) {
                const response: Record<string, any> = (
                    await PB_API_instance.get(
                        `parish/nearbyparishes?longitude=${lng}&latitude=${lat}`
                    )
                ).data;
                const fetchedParish = new ParishPaginatedModel(response);
                state.nearByParishes = fetchedParish.parishes.map((parish) => {
                    // console.log('latlong parish',parish.latLong.longitude)
                    return Object.assign({}, parish, {
                        position: {
                            lat: parish.geoLocation?.coordinates[1],
                            lng: parish.geoLocation?.coordinates[0],
                        },
                    });
                });
                // console.log('results:', state.result)
                state.center_point = {lat, lng};
                return Promise.resolve(null);
            }
        } catch (e: any) {
            return Promise.reject({message: e.response ? e.response.data.message : e.message});
        } finally {
            commit("setLoading", false);
        }
    },

    async fetchDefaultParishes({state, commit}) {
        commit("setLoading", true);
        try {
            let response = await axios.get(`https://geolocation-db.com/json/`);

            let lat = Number(response.data.latitude);
            let lng = Number(response.data.longitude);
            /* const center = geoFireX.point(lat, lng);
                        const radius = 30;  // 30 KM
                        const field = 'location';
                        const query = parishGeoRef.within(center, radius, field, {log: true});
                        state.nearByParishes = await get(query);
                        state.nearByParishes.forEach(parish => {
                            // console.log('latlong parish',parish.latLong.longitude)
                            parish.position = {lat: parish.location.geopoint.latitude, lng: parish.location.geopoint.longitude}
                        })
                         console.log('results:', state.nearByParishes)*/
            commit("setLoading", false);
            state.center_point = {lat, lng};
            return Promise.resolve(null);
        } catch (e: any) {
            commit("setLoading", false);
            return Promise.reject(e);
        }
    },
    async fetchParishPrograms({commit}, parishID = "") {
        try {
            commit("setLoading", true);
            const response: Record<string, any> = (
                await PB_API_instance.get(`parish-program/parish?parishId=${parishID}`)
            ).data;
            commit("setParishPrograms", new ParishProgramPaginatedModel(response).parishPrograms);
        } catch (e: any) {
            return Promise.reject(
                "Failed to fetch Parish Programs" + " " + e.message
            );
        } finally {
            commit("setLoading", false);
        }
    },
    async fetchSelectedParish({commit}, parishID = "") {
        try {
            commit("setLoading", true);
            const response: Record<string, any> | null = (
                await PB_API_instance.get(`parish/${parishID}`)
            ).data;
            if (response) {
                commit("setSelectedParish", ParishModel.fromMongo(response));
            }
        } catch (e: any) {
            return Promise.reject("Failed to fetch Parish" + " " + e.message);
        } finally {
            commit("setLoading", false);
        }
    },

    async fetchUserParish({commit, rootGetters}) {
        try {
            commit("setLoading", true);
            const parishID = rootGetters["user/get_user"].parishId?.id
                ? rootGetters["user/get_user"].parishId?.id
                : "";
            const response: Record<string, any> | null = (
                await PB_API_instance.get(`parish/${parishID}`)
            ).data;
            if (response) {
                commit("setUserParish", ParishModel.fromMongo(response));
            }
        } catch (e: any) {
            return Promise.reject("Failed to fetch Parish" + " " + e.message);
        } finally {
            commit("setLoading", false);
        }
    },
    async save_parish_request(
        {commit, dispatch, rootGetters}, [parish, images]: [ParishRequestModel, File[]]) {
        try {
            commit("setLoading", true);
            if (images.length > 0) {
                parish.images = await Promise.all(
                    images.map((image) =>
                        dispatch(
                            "utils/uploadImageToFirebase",
                            {
                                file: image,
                                folder: `parish_photos/${parish.name
                                    .concat(parish.address)
                                    .split(" ")
                                    .join("_")}`,
                            },
                            {root: true}
                        )
                    )
                );
            }
            const parishProvince = [...rootGetters["diocese/get_diocese"]].filter(
                (diocese_) => parish.diocese === diocese_.id
            )[0].province;
            parish.province = parishProvince ? parishProvince.id : null;
            parish.createdBy = rootGetters["user/get_user"].id
                ? rootGetters["user/get_user"].id
                : "";

            await PB_API_instance.post("parish-request", parish.toMongoJSON());
            return Promise.resolve({
                message: "Parish Request Successfully sent",
            });
        } catch (e: any) {
            return Promise.reject({message: e.response ? e.response.data.message : e.message});
        } finally {
            commit("setLoading", false);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
