import {MassBookingRequestModel} from "@/models/massBookingRequest";
import {PB_API_instance} from "@/config/axios_config";

const state = {
    loading: false,
    error: false
}

const getters = {
    get_loading: (state) => state.loading,
    getError: state => state.error,
}

const mutations = {
    set_loading(state, payload) {
        state.loading = payload
    }
}

const actions = {
    async save_mass_booking_request({commit, rootGetters}, bookingRequest: MassBookingRequestModel) {

        try {
            commit('set_loading', true);
            bookingRequest.createdBy = rootGetters['user/get_user'].id;
            bookingRequest.parishID = rootGetters['user/get_user'].parishId?.id;
            await PB_API_instance.post('mass-booking-request', bookingRequest.toMongoJSON())
            return Promise.resolve({message: 'Mass Booking Request sent'});
        } catch (e: any) {
            return Promise.reject({message: 'Failed to save mass booking request' + ' ' + e.message})
        } finally {
            commit('set_loading', false)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
